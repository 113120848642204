import React from "react";

const MiniLogo = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1041 20.5002L8.19938 19.0227L10.1028 17.6802C10.1327 17.6802 10.1638 17.6802 10.1937 17.6802C10.2236 17.6802 10.2534 17.6789 10.2833 17.6776C11.6349 17.6438 12.8918 17.2296 13.9512 16.5376C15.0107 15.8455 15.8728 14.8769 16.4338 13.7357C16.6649 13.2669 16.844 12.7697 16.9661 12.249C17.0881 11.7283 17.1531 11.1869 17.1531 10.6299C17.1531 8.68233 16.3649 6.91912 15.0886 5.6428C13.8123 4.36649 12.0491 3.57707 10.1015 3.57837V2.16832V0.75827C12.8281 0.75827 15.2964 1.8632 17.0829 3.64978C18.8721 5.43246 19.9771 7.9007 19.9771 10.6273C19.9771 13.3254 18.8942 15.7715 17.1388 17.5542C15.3834 19.3369 12.9567 20.4548 10.2677 20.4977C10.2404 20.4977 10.2132 20.4989 10.1859 20.4989C10.1599 20.4989 10.1327 20.5002 10.1041 20.5002Z"
        fill="url(#paint0_linear_1_670)"
      />
      <path
        d="M16.449 7.1005V7.1031C16.4503 7.17321 16.449 7.24333 16.4464 7.31344C16.4438 7.38355 16.4399 7.45237 16.436 7.52118C16.3282 9.17533 15.5856 10.6568 14.4495 11.7254C13.3134 12.7939 11.7852 13.4483 10.1038 13.4496L10.1025 13.4483L10.1012 13.447C8.41976 13.447 6.89026 12.7926 5.75547 11.7254C4.62068 10.6581 3.8767 9.17662 3.76894 7.52118C4.33114 6.3799 4.83102 4.27132 5.8905 3.58058C6.94998 2.88983 8.57037 3.61433 9.92199 3.58058C8.98975 3.62862 8.15359 4.03761 7.54984 4.66992C6.94609 5.30224 6.57605 6.15917 6.57605 7.1018C6.57605 8.07689 6.97076 8.9572 7.60827 9.59471C8.24577 10.2322 9.12738 10.6256 10.1012 10.6256L10.1025 10.6269L10.1038 10.6282C11.0776 10.6295 11.9592 10.2348 12.5967 9.596C13.2355 8.9572 13.6302 8.07559 13.6302 7.1005H15.0402H16.449Z"
        fill="url(#paint1_linear_1_670)"
      />
      <path
        d="M10.1023 0.754486L11.9512 2.28528L10.1036 3.57458C10.0737 3.57458 10.0426 3.57458 10.0127 3.57458C9.98286 3.57588 9.95299 3.57588 9.92313 3.57718C8.57151 3.61094 7.31467 4.02512 6.25519 4.71716C5.1957 5.4092 4.33357 6.3778 3.77267 7.51908C3.54156 7.9878 3.36238 8.48508 3.24033 9.00574C3.11828 9.52639 3.05077 10.0691 3.05077 10.6274C3.05077 12.575 3.83889 14.3382 5.1152 15.6145C6.39152 16.8908 8.15473 17.6803 10.1023 17.679V19.089V20.4991C7.37569 20.4991 4.90746 19.3941 3.12088 17.6076C1.3343 15.8223 0.22937 13.354 0.22937 10.6274C0.22937 7.92937 1.31222 5.48321 3.06764 3.70053C4.82306 1.91784 7.24975 0.79993 9.93741 0.757083C9.96468 0.757083 9.99194 0.755784 10.0192 0.755784C10.0465 0.754486 10.0737 0.754486 10.1023 0.754486Z"
        fill="url(#paint2_linear_1_670)"
      />
      <path
        d="M16.4487 7.0997L15.0386 7.76708L13.6286 7.0997C13.6273 6.12591 13.2326 5.24561 12.595 4.6068C11.9575 3.96799 11.0759 3.57458 10.1021 3.57458L10.1008 2.16324V0.754486C10.1294 0.754486 10.1567 0.754486 10.1839 0.755784C10.2112 0.757083 10.2385 0.757083 10.2657 0.757083C11.9809 0.801228 13.526 1.52443 14.6426 2.66831C15.7592 3.81219 16.4474 5.37415 16.4487 7.0997Z"
        fill="url(#paint3_linear_1_670)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_670"
          x1="14.0854"
          y1="17.6155"
          x2="14.0854"
          y2="3.92309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#247FFF" />
          <stop offset="0.2074" stopColor="#2283FE" stopOpacity="0.7926" />
          <stop offset="0.4343" stopColor="#1C91FC" stopOpacity="0.5657" />
          <stop offset="0.6703" stopColor="#13A6F9" stopOpacity="0.3297" />
          <stop offset="0.9112" stopColor="#06C5F4" stopOpacity="0.0888" />
          <stop offset="1" stopColor="#00D2F2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_670"
          x1="11.1711"
          y1="9.48451"
          x2="6.26942"
          y2="4.58288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D48D" />
          <stop offset="1" stopColor="#00D48D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_670"
          x1="6.0896"
          y1="3.68083"
          x2="6.0896"
          y2="17.3781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D2F2" />
          <stop offset="0.2137" stopColor="#02CEF3" />
          <stop offset="0.4365" stopColor="#07C1F5" />
          <stop offset="0.6634" stopColor="#10ACF8" />
          <stop offset="0.8919" stopColor="#1D8FFC" />
          <stop offset="1" stopColor="#247FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_670"
          x1="14.9217"
          y1="5.61026"
          x2="11.4483"
          y2="2.37425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D48D" />
          <stop offset="0.2532" stopColor="#00D4A2" />
          <stop offset="0.7744" stopColor="#00D3D9" />
          <stop offset="1" stopColor="#00D2F2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MiniLogo;
