import React from "react";

const CommunityGuidelines = () => {
  return (
    <div className="p-8  text-gray-800">
      {/* Header */}
      <h1 className="text-2xl font-bold text-center mb-6">
        Community Guidelines
      </h1>

      {/* Introduction */}
      <p className="mb-6 text-center">
        The Everyone Platform is dedicated to fostering a respectful, safe, and
        inclusive environment where users from around the world can connect and
        share openly. We ask all users to follow these guidelines to help keep{" "}
        <strong>Everyone</strong> a place of fair and meaningful global
        connection.
      </p>

      {/* Sections */}
      <div className="space-y-8">
        {/* Section 1 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">1. Our Values</h2>
          <p>
            We believe in creating a global, fair-access space where every
            user’s voice has an equal opportunity to be seen. Our guidelines are
            designed to promote respect, diversity, and constructive
            interaction, ensuring <strong>Everyone</strong> remains an open
            platform for all.
          </p>
        </div>

        {/* Section 2 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            2. A Culture of Giving for Value
          </h2>
          <p>
            <strong>Everyone</strong> embraces the concept of{" "}
            <em>giving for value</em>, an opportunity to show appreciation and
            support for the content that brings you joy or value. Whether it’s a
            laugh from a cat video or inspiration from a helpful post, users can
            give small token amounts, like one penny, to support creators
            directly. Importantly, <strong>Everyone</strong> charges no platform
            fee for converting cash to tokens, making it easy to give back and
            foster a generous, appreciative community.
          </p>
        </div>

        {/* Section 3 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            3. Content Moderation and User Reporting
          </h2>
          <p>
            <strong>Everyone</strong> utilizes both advanced AI-powered
            moderation tools and human moderators to maintain a high standard of
            content and safety on our platform.
          </p>
          <ul className="list-disc list-inside space-y-2 mt-4">
            <li>
              <strong>User Flagging:</strong> Users can flag posts that they
              believe violate community standards, such as content that is
              offensive, explicit, or harmful. Every flagged post is reviewed by
              a real human moderator to ensure fair, unbiased action.
            </li>
            <li>
              <strong>Global Standards:</strong> Given that{" "}
              <strong>Everyone</strong> is a global platform, we expect users to
              post content suitable for a wide, international audience. If
              content fails to meet our minimum community guidelines, it may be
              made private, pending further review.
            </li>
            <li>
              <strong>Proactive and Reactive Moderation:</strong> Our moderation
              includes proactive tools that automatically filter and flag
              potentially inappropriate content and a reactive process that
              reviews flagged content as reported by users.
            </li>
          </ul>
        </div>

        {/* Section 4 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            4. Be Respectful and Inclusive
          </h2>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>Respect for All:</strong> Users are expected to treat
              others with respect, even when opinions differ. Harassment, hate
              speech, and abusive language are not tolerated.
            </li>
            <li>
              <strong>Inclusive Language:</strong> Use language that is
              respectful and considerate of all perspectives, backgrounds, and
              experiences.
            </li>
          </ul>
        </div>

        {/* Section 5 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">5. Content Guidelines</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>Appropriate Content:</strong> Only post content that is
              suitable for a general, global audience. Explicit, violent, or
              overly graphic content is not permitted.
            </li>
            <li>
              <strong>Spam-Free Zone:</strong> Avoid repetitive or irrelevant
              content. <strong>Everyone</strong> is for genuine connections, not
              spam or excessive self-promotion.
            </li>
            <li>
              <strong>Protect Private Information:</strong> Do not share private
              or sensitive information about yourself or others.
            </li>
          </ul>
        </div>

        {/* Section 6 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            6. Enforcement and Consequences
          </h2>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>Moderation Actions:</strong> Posts that violate these
              guidelines may be removed or made private, and users may receive
              warnings or be temporarily or permanently banned for repeated
              violations. Our moderation actions are applied consistently to
              maintain a safe environment.
            </li>
            <li>
              <strong>Transparency:</strong> If content is removed or moderated,
              we aim to notify the author with a clear explanation whenever
              possible, helping maintain transparency and understanding.
            </li>
          </ul>
        </div>

        {/* Section 7 */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            7. Feedback and Continuous Improvement
          </h2>
          <p>
            We are dedicated to improving <strong>Everyone</strong> as a safe,
            inclusive platform. We review our guidelines and moderation
            practices regularly, considering feedback from our community. If you
            have questions or suggestions regarding these guidelines, please
            contact us at{" "}
            <a href="mailto:support@everyone.world" className="text-blue-500">
              support@everyone.world
            </a>
            .
          </p>
        </div>
      </div>

      {/* Closing Statement */}
      <p className="mt-8 text-center">
        Thank you for helping us make <strong>Everyone</strong> a welcoming and
        respectful space for all!
      </p>
    </div>
  );
};

export default CommunityGuidelines;
