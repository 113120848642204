import React, { useContext, useState } from "react";

import { AuthContext } from "components/context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";

export default function EmailRegisterPopup({ setAuthType, close }) {
  const { setAuth, auth } = useContext(AuthContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!form.email || !form.password) {
        toast.error("Fill all the fields");
        return;
      }

      setAuthType((prev) => ({ ...prev, authType: "loading" }));

      const res = await axios.post(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/register",
        {
          ...form,
          authMode: "email",
        }
      );

      if (res.data.error) {
        toast.error(res.data.error);
        setAuthType((prev) => ({ ...prev, authType: "email_register" }));
      } else {
        window.localStorage.setItem("token", res.data.token);
        toast.success("Created Account");
        setAuthType((prev) => ({ ...prev, authType: "dob" }));
      }
    } catch (error) {
      if (!error.response) {
        toast.error("Network error, please try again");
      }

      //   toast.error(error.response.data?.message);
      toast.error("Something went wrong");
      setAuthType((prev) => ({ ...prev, authType: "email_login" }));
    }
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12 max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full h-full">
          <div className="flex justify-between h-full flex-col text-zinc-900 space-y-3">
            <form onSubmit={handleSubmit} className="space-y-3 mb-10">
              <div className="text-center pb-4  text-2xl font-poppins text-neutral-900 font-medium">
                Sign up
              </div>
              <h2
                onClick={() =>
                  setAuthType((prev) => ({
                    ...prev,
                    authType: "custom_register",
                  }))
                }
                className="cursor-pointer text-right text-zinc-500 underline"
              >
                Sign up with Phone Number
              </h2>

              <input
                name="email"
                type="email"
                onChange={handleChange}
                className="border border-gray-300  p-3 w-full rounded-md"
                placeholder="Email or username"
              />
              <input
                name="password"
                type="password"
                onChange={handleChange}
                className="border border-gray-300  p-3 w-full rounded-md"
                placeholder="Enter password"
              />

              <input
                type="submit"
                value="Submit"
                aria-label="Sign Up"
                className="justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />
            </form>
          </div>

          <div>
            <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
              By continuing, you agree to everyone’s{" "}
              <span className="text-black text-[15px] font-poppins">
                {" "}
                Terms of Service
              </span>{" "}
              and confirm that you have read everyone’s
              <span className="text-black font-poppins text-[15px]">
                {" "}
                Privacy Policy
              </span>
            </div>
            <div className="text-center mt-3 text-black text-[15px] font-poppins">
              Already have an Account?{" "}
              <button
                onClick={() =>
                  setAuthType((prev) => ({ ...prev, authType: "login" }))
                }
                className="text-rose-500 hover:underline"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
