import React, { useContext, useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import "./LandingPage.scss";
import {
  bgLottieOptions,
  calculateCountdown,
  landingData,
  logoLottieOptions,
} from "./constant";
import fullLogo from "../../assets/fullLogo.json";
import background from "../../assets/background.json";
// import mobile_background from "../../assets/mobile_version.json";
import mobile_background from "../../assets/mobile_bg.png";
import globe from "../../assets/old-globe.png";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import MiniLogo from "assets/svg/MiniLogo";
import VerticalSwiper from "components/VerticalSwiper";
import Modal from "components/Modal";
import AmbassadorForm from "components/AmbassadorForm";
import SubscribeService from "services/subsribe.service";
import CircleLoader from "components/CircleLoader";
import { validateEmail } from "./constant/index";
import ToasterService from "utils/toaster.util";
import PublicNetwork from "components/PublicNetwork";
import AuthPopup from "components/popups/auth/AuthPopup";
import { AuthContext } from "components/context/AuthContext";
import axios from "axios";
import BgAnimation from "components/Landing/BgAnimation";
import { Linkedin, Instagram, Facebook, Twitter, Youtube } from "./Svg";
const LandingPage = () => {
  const [countdown, setCountdown] = React.useState(calculateCountdown());
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    linkedInURL: "",
    twitterURL: "",
    instagramURL: "",
    country: "",
    message: "",
    termsAndConditions: false,
  });
  let validEmail = validateEmail(formData.email);
  const { auth, setAuth, setAuthType } = useContext(AuthContext);

  async function getAuthStatus(token) {
    setAuth((p) => ({ ...p, loading: true }));

    try {
      const res = await axios.get(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.data.authenticated)
        setAuthType({ authType: "register", open: true });

      if (res.data.authenticated && res.data.user) {
        setAuth({
          ...auth,
          isLoggedIn: true,
          user: res.data.user,
          useCase: res.data.user.useCase,
          dateOfBirth: res.data.user.dateOfBirth,
          username: res.data.user.username,
          phone: res.data.user.phone?.number,
          countryCode: res.data.user.phone?.code,
        });

        if (!res.data.user.useCase)
          setAuthType({ authType: "dob", open: true });
      }
    } catch (error) {
      console.error(error);
      if (!error.response) console.error("Network error, please try again");
      else console.error("Something went wrong, please try again");
    } finally {
      setAuth((p) => ({ ...p, loading: false }));
    }
  }

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      setAuth({ isLoggedIn: false });
      setAuthType({ authType: "register", open: true });
    } else {
      getAuthStatus(token);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open) {
      setIsChecked(false);
    }
  }, [open]);

  const handleCheckboxChange = () => {
    if (!formData.email || validEmail) {
      ToasterService.showError("Enter your valid Email");
      setIsChecked(false);
    } else {
      setOpen(true);
      setIsChecked(true);
    }
  };

  useEffect(() => {
    setCountdown(calculateCountdown());
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // const handleScroll = (event) => {
  //   const backgroundParent = document.querySelector(".backgroundParent");
  //   if (
  //     event.key === "ArrowUp" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("up-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: -700, behavior: "smooth" });
  //   } else if (
  //     event.key === "ArrowDown" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("down-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: 700, behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    window.addEventListener("keydown", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleScroll);
    };
  }, []);

  const SubscribeAsAmbassador = () => {
    SubscribeService.subscribe({ email: formData.email }, setLoading, setOpen);
  };

  const [scrollY, setScrollY] = useState(0);
  const backgroundParentRef = useRef(null);

  const handleScroll = () => {
    if (backgroundParentRef.current) {
      setScrollY(backgroundParentRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const backgroundParent = backgroundParentRef.current;
    if (backgroundParent) {
      backgroundParent.addEventListener("scroll", handleScroll);

      return () => {
        backgroundParent.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * landingData.length);
    setCurrentIndex(randomIndex);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <BgAnimation />
      <div className="backgroundParent" ref={backgroundParentRef}>
        <div className="mainLanding">
          <div className="upperPortion">
            {loading && <CircleLoader />}

            <div
              className={`upper__content ${scrollY > 100 ? "fade-out" : ""}`}
            >
              <div className="lottie-animation">
                <Lottie options={logoLottieOptions(fullLogo)} />
              </div>

              <div className="text__parent">
                <h1 className="heading">
                  Free Public Channels Visible to the World on One Global Stream
                </h1>
                <p className="info">
                  Join the Beta to be the first to receive a early channel
                  number, exclusive access, priority support, and special
                  recognition as an early adopter. All free for life.
                </p>
              </div>

              <div className="form">
                <div className="input_parent">
                  <input
                    placeholder="Enter your Email"
                    type="text"
                    className="text__input"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                  />
                  <Button
                    onClick={SubscribeAsAmbassador}
                    disabled={!formData.email || validEmail}
                  >
                    Join Beta
                  </Button>
                </div>
                {/* <div className="checkbox__parent">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                <div className="checkbox__text">
                  I want to be an Ambassador. <span>Learn More</span>
                </div>
              </div> */}
              </div>
              {/* <div className="timer">
                <div className="days">
                  <div className="count">{countdown.days}</div>
                  <div className="count_name">Days</div>
                </div>
                <div className="colon">:</div>
                <div className="days">
                  <div className="count">{countdown.hours}</div>
                  <div className="count_name">Hours</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.minutes}</div>
                  <div className="count_name">Minutes</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.seconds}</div>
                  <div className="count_name">Seconds</div>
                </div>
              </div> */}
            </div>

            <div
              // className="bottom__content"
              className={`bottom__content ${scrollY > 100 ? "fade-out" : ""}`}
            >
              <div className="bottom__text">
                By joining as a beta user or ambassador, you agree to receive
                communications about our beta release and launch. Early channel
                numbers are not guaranteed and will be distributed on a
                first-come, first-served basis, with some channel numbers
                reserved for existing streams and networks.
              </div>
              <footer className="w-full py-6">
                <div className="container mx-auto px-4">
                  {/* Navigation Links */}
                  <nav className="flex flex-wrap justify-center mb-6 gap-6 text-white font-[Onest] text-[13.35px]">
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Terms of Service
                    </a>
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Community Guidelines
                    </a>
                  </nav>

                  {/* Social Media Icons */}
                  <div className="flex justify-center gap-6">
                    <a
                      href="#linkedin"
                      className="hover:text-blue-600 transition-colors"
                    >
                      <Linkedin />
                    </a>
                    <a
                      href="#instagram"
                      className="hover:text-pink-600 transition-colors"
                    >
                      <Instagram />
                    </a>
                    <a
                      href="#facebook"
                      className="hover:text-blue-700 transition-colors"
                    >
                      <Facebook />
                    </a>
                    <a
                      href="#twitter"
                      className="hover:text-blue-400 transition-colors"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="#youtube"
                      className="hover:text-red-600 transition-colors"
                    >
                      <Youtube />
                    </a>
                  </div>
                </div>
              </footer>
              <div className="copyRight">
                <MiniLogo />
                <div className="bottom__text">© 2024 Everyone World, Inc.</div>
              </div>
            </div>
            {/* <div className="actionButtons">
            <div className="cursor up-arrow" onClick={handleScroll}>
              <UpArrow />
            </div>
            <div>
              <MouseIcon />
            </div>
            <div className="cursor down-arrow" onClick={handleScroll}>
              <DownArrow />
            </div>
          </div> */}
            {/* <div className="globe-container">
              <img src={globe} alt="Rotating Globe" className="globe" />
            </div> */}
          </div>

          <AuthPopup />

          {/* <PublicNetwork />

        <VerticalSwiper
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        /> */}
        </div>
        {/* <Modal setOpen={setOpen} open={open}>
        <AmbassadorForm
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
        />
      </Modal> */}
      </div>
    </div>
  );
};

export default LandingPage;
