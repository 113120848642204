import React from "react";

const PrivacyPolicy = () => {
  const platformFeatures = [
    "A chronological feed for sharing content.",
    "Encrypted messaging and video meetings.",
    "Gig postings and item sales.",
    "Dating features.",
    "A privacy-focused search browser.",
    "Blockchain integration to record channel ownership and content hashes.",
  ];

  const accountInfo = [
    { field: "Username", description: "To identify you on the platform." },
    { field: "Password", description: "To secure your account." },
    {
      field: "Email Address",
      description: "For account verification and communication.",
    },
    {
      field: "Phone Number",
      description:
        "For enhanced security features like two-factor authentication.",
    },
    {
      field: "Date of Birth",
      description:
        "To verify that you meet the minimum age requirement of 18 years.",
    },
  ];

  const optionalInfo = [
    "Profile Picture",
    "Bio",
    "Location",
    "Interests",
    "Occupation",
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm p-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2">
            Privacy Policy for The Everyone Platform
          </h1>
          <p className="text-gray-600">Effective Date: June 5, 2024</p>
        </div>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            Welcome to The Everyone Platform
          </h2>
          <p className="text-gray-700 mb-4">
            Your privacy is important to us. This Privacy Policy explains how
            The Everyone Platform ("we," "us," or "our") collects, uses,
            discloses, stores, and protects your personal information when you
            use our services. Please read this policy carefully to understand
            our practices regarding your information and how we will treat it.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700 mb-4">
            The Everyone Platform is an all-in-one service that offers:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            {platformFeatures.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <p className="mt-4 text-gray-700">
            By using our services, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            2. Information We Collect
          </h2>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">
              2.1. Account Information
            </h3>
            <p className="text-gray-700 mb-3">
              When you create an account, we collect:
            </p>
            <ul className="space-y-2">
              {accountInfo.map(({ field, description }, index) => (
                <li key={index} className="text-gray-700">
                  <span className="font-medium">{field}</span>: {description}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">
              2.2. Optional Profile Information
            </h3>
            <p className="text-gray-700 mb-3">
              You may choose to provide additional information to personalize
              your profile:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              {optionalInfo.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
            <p className="mt-4 text-gray-700">
              Providing this information is optional and helps you connect with
              other users.
            </p>
          </div>
        </section>
        <div className="max-w-4xl mx-auto px-6 py-10">
          {/* Section 2.3 */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2.3. Location Data</h2>
            <p className="text-gray-700">
              With your explicit consent, we may collect precise geolocation
              data to enhance features like dating and local listings. You can
              enable or disable location services at any time through your
              device settings.
            </p>
          </div>

          {/* Section 2.4 */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              2.4. User-Generated Content
            </h2>
            <p className="text-gray-700 mb-4">
              Our platform allows you to create and share content, including:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Text</li>
              <li>Images</li>
              <li>Videos</li>
              <li>Audio recordings</li>
            </ul>
            <p className="text-gray-700">
              Please be aware that any content you share may be viewed by other
              users, depending on your privacy settings.
            </p>
          </div>

          {/* Section 2.5 */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2.5. Communication Data</h2>
            <p className="text-gray-700 mb-4">
              We provide end-to-end encrypted messaging and video meetings. This
              means:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>
                Messages and video calls are encrypted on your device and can
                only be decrypted by the intended recipients.
              </li>
              <li>
                We do not have access to the content of your messages or video
                calls.
              </li>
              <li>
                We do not store message or video content on our servers once
                delivered.
              </li>
            </ul>
          </div>

          {/* Section 2.6 */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2.6. Blockchain Data</h2>
            <p className="text-gray-700 mb-4">
              To maintain transparency and security, we use blockchain
              technology to record:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>The owner of each channel.</li>
              <li>The channel number.</li>
              <li>
                A cryptographic hash of the channel content (which allows
                verification without revealing the actual content).
              </li>
              <li>The date and time of each post.</li>
            </ul>
            <p className="text-gray-700">
              No personal data is stored on the blockchain; only the above
              information is recorded to ensure data integrity and ownership
              verification.
            </p>
          </div>

          {/* Section 2.7 */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              2.7. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-700 mb-4">
              We use cookies and similar technologies for functionality and
              performance purposes:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Enhancing user experience.</li>
              <li>Remembering your preferences.</li>
              <li>Analyzing platform performance.</li>
            </ul>
            <p className="text-gray-700">
              We do not use cookies for advertising or tracking purposes. You
              can manage your cookie preferences through your browser settings.
            </p>
          </div>

          {/* Section 3 */}
          <div>
            <h2 className="text-2xl font-bold mb-4">
              3. How We Use Your Information
            </h2>
            {/* 3.1 */}
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">
                3.1. To Provide and Improve Our Services
              </h3>
              <p className="text-gray-700 mb-4">We use your information to:</p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Create and manage your account.</li>
                <li>Facilitate secure transactions and communications.</li>
                <li>Personalize your experience based on your activity.</li>
              </ul>
            </div>

            {/* 3.2 */}
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">
                3.2. Personalization
              </h3>
              <p className="text-gray-700 mb-4">
                With your consent, we may use your activity data to provide
                moderate personalization, such as:
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Content recommendations.</li>
                <li>Suggested connections based on your interests.</li>
              </ul>
            </div>

            {/* 3.3 */}
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">3.3. Advertising</h3>
              <p className="text-gray-700">
                We display non-personalized ads to all users. These ads are not
                based on your personal data or browsing history.
              </p>
            </div>

            {/* 3.4 */}
            <div>
              <h3 className="text-xl font-semibold mb-4">
                3.4. Third-Party Service Providers
              </h3>
              <p className="text-gray-700 mb-4">
                We may share your information with third-party service providers
                to:
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Facilitate platform functionality.</li>
                <li>Provide customer support.</li>
                <li>Process transactions.</li>
              </ul>
              <p className="text-gray-700">
                These providers are contractually obligated to protect your
                information and use it solely for services provided to us.
              </p>
            </div>
          </div>
        </div>
        <div className="p-6 max-w-4xl mx-auto">
          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">
              4. Data Storage and Security
            </h2>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">4.1. Data Retention</h3>
              <p className="mb-2">
                We retain your data only as long as necessary to provide our
                services or as required by law.
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Data associated with your account remains accessible as long
                  as your account is active.
                </li>
                <li>
                  If you deactivate or delete your account, we will delete your
                  personal data from our active databases, except where
                  retention is required by law.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">4.2. Security Measures</h3>
              <p className="mb-2">
                We employ robust security protocols to protect your data:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Encryption of data at rest and in transit using
                  industry-standard practices.
                </li>
                <li>Regular security assessments and audits.</li>
                <li>
                  Access controls to limit who can access your information.
                </li>
              </ul>
            </div>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">
              5. Your Rights and Choices
            </h2>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">
                5.1. Access, Correction, and Portability
              </h3>
              <p className="mb-2">You have the right to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Access your personal information.</li>
                <li>Correct or update inaccurate information.</li>
                <li>Request a copy of your data in a portable format.</li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">5.2. Data Deletion</h3>
              <p className="mb-2">
                You can request the deletion of all your personal data. Upon
                verification of your request, we will delete your data except
                where retention is required by law.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">5.3. Opt-Out Options</h3>
              <p className="mb-2">
                You may opt out of all non-essential data collection and
                processing, including:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Personalization features.</li>
                <li>Location data collection.</li>
                <li>Email marketing communications.</li>
              </ul>
            </div>
          </section>

          {/* Repeat similar structure for each section */}
        </div>
        <div className="p-8  text-gray-800">
          {/* Changes to This Privacy Policy Section */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">
              13. Changes to This Privacy Policy
            </h2>
            <p className="mb-4">
              We may update this Privacy Policy from time to time. We will
              notify you of significant changes by:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Posting the updated policy on our website.</li>
              <li>Updating the effective date at the top of this policy.</li>
              <li>Sending a notification via email or through the platform.</li>
            </ul>
            <p className="mt-4">
              Your continued use of our services after any changes indicates
              your acceptance of the updated policy.
            </p>
          </section>

          {/* Contact Us Section */}
          <section>
            <h2 className="text-lg font-semibold mb-4">14. Contact Us</h2>
            <p className="mb-4">
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or our data practices, please contact us:
            </p>
            <ul className="space-y-2">
              <li>
                <strong>Email:</strong>{" "}
                <a
                  href="mailto:privacy@everyone.world"
                  className="text-blue-600"
                >
                  privacy@everyone.world
                </a>
              </li>
              <li>
                <strong>Phone:</strong> +1 (424) 234-1874
              </li>
              <li>
                <strong>Mailing Address:</strong>
                <br />
                The Everyone Platform Privacy Team
                <br />
                1001 Wilshire Blvd.
                <br />
                Los Angeles, CA 90017
                <br />
                United States
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
