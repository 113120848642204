"use client";

import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    loading: false,
    isLoggedIn: false,
    user: {},
    useCase: "",
    dateOfBirth: {},
    username: "",
    usernameSuggestions: [],
    phone: "",
    countryCode: "",
    provider: "",
  });
  const [authType, setAuthType] = useState({
    open: false,
    authType: "register",
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth, authType, setAuthType }}>
      {children}
    </AuthContext.Provider>
  );
}
