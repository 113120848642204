import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "components/context/AuthContext";
import PhoneNumberInput from "./PhoneNumberInput";
import { countryCodes } from "./constants";
import { toast } from "react-toastify";
import axios from "axios";

export default function CustomRegisterPopup({ setAuthType, close }) {
  const { setAuth, auth } = useContext(AuthContext);
  const [code, setCode] = useState("");
  const [selectedCode, setSelectedCode] = useState(countryCodes[0].code);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordLogin, setPasswordLogin] = useState(false);

  const [state, setState] = useState({
    loading: false,
    otpSent: false,
  });

  useEffect(() => {
    setAuth((prev) => {
      return {
        ...prev,
        countryCode: selectedCode,
        phone: phoneNumber.split("-").join(""),
      };
    });
  }, [selectedCode, phoneNumber]);

  async function sendOtp() {
    try {
      const payload = {
        phone: auth.countryCode + auth.phone,
        authMode: "phone",
      };

      setState((prev) => ({ ...prev, loading: true }));

      const res = await axios.post(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/login",
        payload
      );

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        setState((prev) => ({ ...prev, otpSent: true }));
        toast.success("Verification code sent successfully");
      }
    } catch (error) {
      if (!error.response) {
        toast.error("Network error, please try again");
      }

      //   toast.error(error.response.data?.message);
      toast.error("Failed to send Verification Code");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }

  async function handleSubmit(e) {
    try {
      const payload = {
        phone: auth.countryCode + auth.phone,
        otp: code,
        createAccount: true,
      };

      setState((prev) => ({ ...prev, loading: true }));

      const res = await axios.post(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/verify-otp",
        payload
      );

      if (res.data.error) {
        toast.error(res.data.error);
      } else if (res.data.token) {
        setAuth({
          token: res.data.token,
          user: res.data.user,
        });
        window.localStorage.setItem("token", res.data.token);
        window.location.reload();

        toast.success("Account Created");
        // setAuthType((prev) => ({ ...prev, authType: "dob" }));
        close();
      }
    } catch (error) {
      if (!error.response) {
        toast.error("Network error, please try again");
      }

      //   toast.error(error.response.data?.message);
      toast.error("Something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12 max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full h-full">
          <div className="flex justify-between h-full flex-col text-zinc-900 space-y-3">
            <div className="space-y-3 mb-10">
              <div className="text-center pb-4  text-2xl font-poppins text-neutral-900 font-medium">
                Login
              </div>
              <h2
                onClick={() =>
                  setAuthType((prev) => ({ ...prev, authType: "email_login" }))
                }
                className="cursor-pointer text-right text-zinc-500 underline"
              >
                Login with email and password
              </h2>
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                selectedCode={selectedCode}
                setSelectedCode={setSelectedCode}
              />
              {passwordLogin ? (
                <div className="pb-2">
                  <input
                    name="password"
                    type="password"
                    className="border border-gray-300  p-3 w-full rounded-md"
                    placeholder="Enter password"
                  />
                  <div className="flex items-center  gap-2">
                    <p
                      onClick={() => setPasswordLogin((p) => !p)}
                      className="pt-2 text-zinc-600 cursor-pointer"
                    >
                      Forgot Password
                    </p>
                    <div className="h-6 mt-2 w-[1px] bg-zinc-400"></div>
                    <p
                      onClick={() => setPasswordLogin((p) => !p)}
                      className="pt-2 text-zinc-600 cursor-pointer"
                    >
                      Login with code
                    </p>
                  </div>
                </div>
              ) : (
                <div className="pb-2">
                  <div className="flex items-center justify-between border border-gray-300 rounded-md p-2">
                    <input
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Enter 6 Digit Code"
                      className="w-20 flex-1 border-none focus:outline-none"
                    />
                    <button
                      onClick={sendOtp}
                      disabled={state.loading || phoneNumber.length < 10}
                      type="button"
                      className="text-sm bg-rose-500 text-white rounded-md p-2 ml-2 disabled:opacity-50"
                    >
                      {state.loading
                        ? "Sending..."
                        : state.otpSent
                        ? "Resend Code"
                        : "Send Code"}
                    </button>
                  </div>
                  <p
                    onClick={() => setPasswordLogin((p) => !p)}
                    className="pt-2 text-zinc-600 cursor-pointer"
                  >
                    Login with password
                  </p>
                </div>
              )}
            </div>

            <div>
              <input
                type="button"
                onClick={handleSubmit}
                disabled={state.loading || !state.otpSent}
                value="Verify"
                aria-label="Sign Up"
                className="disabled:opacity-50 justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />

              <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
                By continuing, you agree to everyone’s{" "}
                <span className="text-black text-[15px] font-poppins">
                  {" "}
                  Terms of Service
                </span>{" "}
                and confirm that you have read everyone’s
                <span className="text-black font-poppins text-[15px]">
                  {" "}
                  Privacy Policy
                </span>
              </div>
              <div className="self-center mt-3 text-black text-[15px] font-poppins text-center">
                {"Don't have an Account?"}
                <button
                  onClick={() =>
                    setAuthType((prev) => ({ ...prev, authType: "register" }))
                  }
                  className="text-rose-500 hover:underline"
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
