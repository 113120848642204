import http from "./http.service";
import Promisable from "./promisable.service";

const url = "api/saveEmailAddress";

const SubscribeService = {
  subscribe: async (payload, setLoading, callback) => {
    setLoading(true);
    const [success, error] = await Promisable.asPromise(
      // http.post(`http://backend.everyone.world:9500/${url}`, payload)
      http.post(`https://api.everyone.world/${url}`, payload)
    );

    if (success) {
      setLoading(false);
      // callback(true);
    }
    setLoading(false);

    return [success, error];
  },
};

export default SubscribeService;
