import React from "react";
import "./dots.scss";

const Dots = ({ dotImage, numDots }) => {
  const generateRandomPosition = () => {
    const x = Math.random() * 100;
    const y = Math.random() * 100;
    return { x, y };
  };

  const generateRandomDelay = () => {
    return Math.random() * 5 + 0 + "s"; // Random delay between 0s and 8s
  };

  const generateRandomDuration = () => {
    return Math.random() * 10 + 4 + "s"; // Random duration between 4s and 10s
  };

  const stars = Array.from({ length: numDots }, (_, index) => {
    const { x, y } = generateRandomPosition();
    const animationDelay = generateRandomDelay();
    const animationDuration = generateRandomDuration();

    return (
      <img
        key={index}
        width={100}
        height={100}
        className="dot"
        src={dotImage}
        style={{
          left: `${x}%`,
          top: `${y}%`,
          animationDelay,
          animationDuration,
        }}
        alt={`star ${index}`}
      />
    );
  });

  return <div className="fixed w-screen h-screen z-10">{stars}</div>;
};

export default React.memo(Dots);
