import React from "react";

const BusinessModel = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">Business Model</h2>
      <p className="text-gray-600 mb-4">
        Robust Revenue Streams and Scalable Growth
      </p>
      <p className="text-gray-700 mb-6">
        Everyone offers free access to a wide range of features, encouraging
        broad user adoption and engagement. To effectively monetize this user
        base, we present two primary paid options: Spotlighted Posts and
        Featured Channels. These premium features allow users to enhance their
        visibility within the platform's global feed, providing real value while
        keeping the monetization model straightforward.
      </p>

      <h3 className="text-lg font-bold mb-4">Key Revenue Sources</h3>
      <ul className="text-gray-700 space-y-2">
        <li>
          <span className="font-medium">Spotlighted Posts ($29.99 each):</span>{" "}
          Users can boost individual posts to reach a wider audience, increasing
          engagement and visibility.
        </li>
        <li>
          <span className="font-medium">Featured Channels ($29.99 each):</span>{" "}
          Ideal for brands or individuals seeking sustained prominence, users
          can promote their entire channels for a stronger presence on the
          platform.
        </li>
      </ul>

      <h3 className="text-lg font-bold mb-4">Pricing Model</h3>
      <p className="text-gray-700 mb-6">
        A flat fee of $29.99 per use for both options simplifies the purchasing
        decision, making it easier for users to take advantage of these
        features.
      </p>

      <h3 className="text-lg font-bold mb-4">User Participation Estimate</h3>
      <p className="text-gray-700 mb-6">
        Based on industry benchmarks and conservative estimates, we anticipate
        that approximately 0.5% of users will utilize each of these paid
        features. This estimate is based on 0.5% of users, with trends
        indicating 1%-3% of users could utilize paid features. Our estimates
        below are based on 0.5% of users.
      </p>
      <h1 className="text-2xl font-bold mb-6">User Metrics</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-bold mb-4">1 Million Users</h2>
          <ul className="space-y-3">
            <li>
              <span className="font-medium">Spotlighted Posts:</span> 5,000
              users × $29.99 = Approximately $150,000/month
            </li>
            <li>
              <span className="font-medium">Featured Channels:</span> 5,000
              users × $29.99 = Approximately $150,000/month
            </li>
            <li>
              <span className="font-medium">Based on 0.5% of users</span>
            </li>
            <li>
              <span className="font-medium">Total Monthly Revenue:</span> Around
              $300,000
            </li>
            <li>
              <span className="font-medium">Annual Revenue:</span> Approximately
              $3.6 million
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">5 Million Users</h2>
          <ul className="space-y-3">
            <li>
              <span className="font-medium">Spotlighted Posts:</span> 25,000
              users × $29.99 = Approximately $750,000/month
            </li>
            <li>
              <span className="font-medium">Featured Channels:</span> 25,000
              users × $29.99 = Approximately $750,000/month
            </li>
            <li>
              <span className="font-medium">Based on 0.5% of users</span>
            </li>
            <li>
              <span className="font-medium">Total Monthly Revenue:</span> Around
              $1.5 million
            </li>
            <li>
              <span className="font-medium">Annual Revenue:</span> Approximately
              $18 million
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">50 Million Users</h2>
          <ul className="space-y-3">
            <li>
              <span className="font-medium">Spotlighted Posts:</span> 250,000
              users × $29.99 = Approximately $7.5 million/month
            </li>
            <li>
              <span className="font-medium">Featured Channels:</span> 250,000
              users × $29.99 = Approximately $7.5 million/month
            </li>
            <li>
              <span className="font-medium">Based on 0.5% of users</span>
            </li>
            <li>
              <span className="font-medium">Total Monthly Revenue:</span> Around
              $15 million
            </li>
            <li>
              <span className="font-medium">Annual Revenue:</span> Approximately
              $180 million
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">100 Million Users</h2>
          <ul className="space-y-3">
            <li>
              <span className="font-medium">Spotlighted Posts:</span> 500,000
              users × $29.99 = Approximately $15 million/month
            </li>
            <li>
              <span className="font-medium">Featured Channels:</span> 500,000
              users × $29.99 = Approximately $15 million/month
            </li>
            <li>
              <span className="font-medium">Based on 0.5% of users</span>
            </li>
            <li>
              <span className="font-medium">Total Monthly Revenue:</span> Around
              $30 million
            </li>
            <li>
              <span className="font-medium">Annual Revenue:</span> Approximately
              $360 million
            </li>
          </ul>
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-4">
        Potential for Future Monetization Through Coin Offering
      </h2>
      <p className="mb-4">
        There is significant potential for future monetization through the
        distribution of a coin offering. Currently, our platform utilizes the
        EVRY coin, which is distributed as DAO tokens granting voting rights
        within the community. We retain the right to convert these DAO tokens
        into coins in the future, pending regulatory developments in the United
        States. It's important to note that our current business model does not
        guarantee that we will distribute coins or cryptocurrencies, and we are
        not engaging in such distribution at this time. Any future actions will
        be fully compliant with regulatory requirements.
      </p>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">
          Scalable, Sustainable, and User-Focused
        </h3>
        <div>
          <h4 className="text-lg font-bold mb-1">Scalability</h4>
          <p>
            Our revenue model scales naturally with user growth. As the user
            base expands, revenue potential increases without significant
            changes to the platform or additional costs. This leverages network
            effects and heightened user engagement to drive profitability.
          </p>
        </div>
        <div className="mt-4">
          <h4 className="text-lg font-bold mb-1">Sustainability</h4>
          <p>
            To maintain the value and exclusivity of Spotlighted Posts and
            Featured Channels, we can adjust visibility durations or implement
            rotation systems as demand grows. This ensures users continue to
            perceive real value in these paid features, encouraging ongoing
            participation.
          </p>
        </div>
      </div>
      <p className="mb-4">
        Our platform leverages blockchain technology to enhance user experience
        and security. However, we are not a cryptocurrency project. Instead,
        Everyone is a social connection platform, think of it as Social-TV or a
        Global Megaphone, that provides users with innovative ways to engage and
        communicate on a global scale.
      </p>

      <h2 className="text-2xl font-bold mb-4">User-Centric Approach</h2>
      <div className="space-y-4">
        <div>
          <h3 className="text-xl font-bold">Simplicity</h3>
          <p>
            A straightforward flat-rate pricing at $29.99 removes barriers to
            purchase and simplifies revenue forecasting.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Empowerment</h3>
          <p>
            By offering tools that enhance visibility, we empower users to
            achieve their personal or business objectives on the platform.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Accessibility</h3>
          <p>
            Keeping core features free ensures the platform remains accessible
            to all, fostering a diverse and engaged community.
          </p>
        </div>
      </div>
      <h2 className="text-2xl font-bold mt-8 mb-4">Competitive Advantages</h2>
      <div className="space-y-4">
        <div>
          <h3 className="text-xl font-bold">Market Differentiation</h3>
          <p>
            Focusing on user empowerment through visibility tools sets us apart
            in a crowded market.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Predictable Revenue Streams</h3>
          <p>
            Fixed pricing allows for consistent revenue and easier financial
            planning.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Low Operational Costs</h3>
          <p>
            Digital premium features incur minimal additional costs, maximizing
            profit margins.
          </p>
        </div>
      </div>
      <h2 className="text-2xl font-bold mt-8 mb-4">
        Market Potential and Growth Strategy
      </h2>
      <p>
        The global social media market continues to grow rapidly, presenting
        significant opportunities for user acquisition. By prioritizing user
        experience and offering valuable premium features, Eveyone is positioned
        to capture a meaningful share of this expanding market.
      </p>
      <h2 className="text-2xl font-bold mt-8 mb-4">Growth Strategy</h2>
      <div className="space-y-4">
        <div>
          <h3 className="text-xl font-bold">User Acquisition</h3>
          <p>
            Utilize viral growth mechanisms and strategic partnerships to
            accelerate user adoption.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Feature Enhancement</h3>
          <p>
            Continuously improve platform features based on user feedback to
            increase engagement and retention.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Global Expansion</h3>
          <p>
            Localize content and features to appeal to international markets,
            broadening our user base.
          </p>
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-4">Risk Mitigation</h2>

      <ul className="space-y-4">
        <li>
          <h3 className="text-lg font-medium">Market Diversification</h3>
          <p className="text-gray-600">
            By appealing to both individual users and businesses, we diversify
            revenue streams and reduce dependence on a single user segment.
          </p>
        </li>
        <li>
          <h3 className="text-lg font-medium">Adaptive Pricing</h3>
          <p className="text-gray-600">
            Maintain flexibility to adjust pricing or introduce tiered packages
            in response to market conditions.
          </p>
        </li>
        <li>
          <h3 className="text-lg font-medium">Regulatory Compliance</h3>
          <p className="text-gray-600">
            Proactively ensure compliance with global data protection and
            privacy regulations to mitigate legal risks.
          </p>
        </li>
      </ul>

      <p className="mt-4 text-gray-600">
        By aligning our revenue growth directly with user engagement and
        maintaining a user-centric approach, Everyone harnesses the power of a
        unified global stream, the Everyone stream. This singular platform
        connects users worldwide in one cohesive feed, amplifying voices and
        fostering global conversations like never before. Our innovative
        business model not only supports robust financial performance but also
        cultivates a vibrant and interconnected community. By presenting one
        unified stream for everyone, we are redefining social connection and
        positioning ourselves for sustainable success in the competitive social
        media landscape.
      </p>
    </div>
  );
};

export default BusinessModel;
