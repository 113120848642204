import React from "react";

const TermsComponent = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white  rounded-lg">
      <h1 className="text-3xl font-bold text-center mb-4">
        Terms for The Everyone Platform
      </h1>
      <p className="text-center text-gray-600 mb-8">
        Effective Date: June 5, 2024
      </p>

      {/* 1. Acceptance of Terms */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="text-gray-700">
          Welcome to Everyone Platform ("we," "us," or "our"). By accessing or
          using our services, applications, websites, and content (collectively,
          the "Services"), you agree to be bound by these Terms of Service
          ("Terms"). Please read them carefully. If you do not agree to these
          Terms, you must not use our Services.
        </p>
      </section>

      {/* 2. Eligibility */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Eligibility</h2>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Age Requirement:</strong> You must be at least 18 years old
            to access or use the Services.
          </li>
          <li>
            <strong>Legal Capacity:</strong> You have the full power and
            authority to enter into these Terms.
          </li>
        </ul>
      </section>

      {/* 3. Privacy Policy */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Privacy Policy</h2>
        <p className="text-gray-700">
          Your privacy is important to us. Please review our Privacy Policy,
          which explains how we collect, use, and protect your personal
          information.
        </p>
      </section>

      {/* 4. User Accounts */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. User Accounts</h2>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Account Creation:</strong> You agree to provide accurate,
            current, and complete information.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for
            safeguarding your account credentials.
          </li>
          <li>
            <strong>Account Responsibility:</strong> You are responsible for all
            activities that occur under your account.
          </li>
        </ul>
      </section>

      {/* 5. User Conduct and Obligations */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          5. User Conduct and Obligations
        </h2>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            Comply with all applicable laws, regulations, and these Terms.
          </li>
          <li>Use the Services for lawful purposes only.</li>
          <li>Respect the rights and dignity of others.</li>
        </ul>
      </section>

      {/* 6. Content on the Services */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          6. Content on the Services
        </h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">
          User-Generated Content
        </h3>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Your Content:</strong> You retain ownership of any content
            you submit or post on the Services.
          </li>
          <li>
            <strong>Responsibility:</strong> You are solely responsible for your
            User Content.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-4 mb-2">
          Intellectual Property Rights
        </h3>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Our Content:</strong> All content provided by us is
            protected by intellectual property laws.
          </li>
          <li>
            <strong>Limited License:</strong> We grant you a limited,
            non-exclusive license to use the Platform Content.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-4 mb-2">License Grant</h3>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>To Us:</strong> By submitting User Content, you grant us a
            worldwide license to use it in connection with the Services.
          </li>
          <li>
            <strong>To Other Users:</strong> You grant each user a license to
            access and use your User Content through the Services.
          </li>
        </ul>

        <p className="text-gray-700 mt-2">
          We reserve the right to remove or disable access to any User Content
          that violates these Terms or our policies without prior notice.
        </p>
      </section>

      {/* 7. Prohibited Activities */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          7. Prohibited Activities
        </h2>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Harassment and Hate Speech:</strong> Posting or sharing
            defamatory, obscene, or otherwise objectionable content.
          </li>
          <li>
            <strong>Illegal Activities:</strong> Engaging in unlawful
            activities.
          </li>
          <li>
            <strong>Intellectual Property Infringement:</strong> Uploading
            content that infringes on intellectual property rights.
          </li>
          <li>
            <strong>Spam and Misuse:</strong> Sending unsolicited or
            unauthorized advertising.
          </li>
          <li>
            <strong>Security Violations:</strong> Attempting to breach any
            security measures.
          </li>
          <li>
            <strong>Automated Use:</strong> Using automated means to access the
            Services without permission.
          </li>
          <li>
            <strong>Impersonation:</strong> Misrepresenting your affiliation
            with a person or entity.
          </li>
          <li>
            <strong>Interference:</strong> Interfering with the integrity of the
            Services.
          </li>
        </ul>
      </section>

      {/* 8. Community Guidelines */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Community Guidelines</h2>
        <p className="text-gray-700">
          We have established Community Guidelines to maintain a respectful and
          safe environment. Violations may result in content removal or account
          suspension.
        </p>
      </section>
      <div className="p-8  text-gray-800">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            9. Third-Party Services and Content
          </h2>
          <ul className="list-disc ml-6">
            <li>
              <strong>Links:</strong> The Services may contain links to
              third-party websites or services that are not owned or controlled
              by us.
            </li>
            <li>
              <strong>No Endorsement:</strong> We do not endorse or assume
              responsibility for any third-party sites, information, materials,
              products, or services.
            </li>
            <li>
              <strong>User Responsibility:</strong> You acknowledge sole
              responsibility for and assume all risk arising from your use of
              any third-party resources.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            10. Disclaimers and Limitation of Liability
          </h2>
          <div className="mb-4">
            <h3 className="text-xl font-medium">Disclaimers</h3>
            <ul className="list-disc ml-6">
              <li>
                <strong>As-Is Basis:</strong> The Services are provided "as is"
                and "as available" without warranties of any kind, either
                express or implied.
              </li>
              <li>
                <strong>No Warranty:</strong> We do not warrant that the
                Services will be uninterrupted, error-free, secure, or free from
                viruses or other harmful components.
              </li>
              <li>
                <strong>No Responsibility:</strong> We are not responsible for
                any User Content or third-party content that you access through
                the Services.
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-medium">Limitation of Liability</h3>
            <ul className="list-disc ml-6">
              <li>
                To the fullest extent permitted by law, we shall not be liable
                for any indirect, incidental, special, consequential, or
                punitive damages, including loss of profits, data, use,
                goodwill, or other intangible losses, arising out of or related
                to your use of or inability to use the Services.
              </li>
              <li>
                Our total liability to you for any claim arising out of or
                relating to these Terms or the Services is limited to the amount
                you paid us, if any, in the past six months for the Services.
              </li>
            </ul>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">11. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless Everyone Platform
            and its affiliates, officers, agents, employees, and partners from
            any claims, liabilities, damages, losses, and expenses, including
            legal fees, arising out of or connected with:
          </p>
          <ul className="list-disc ml-6">
            <li>Your access to or use of the Services.</li>
            <li>Your violation of these Terms.</li>
            <li>
              Your violation of any third-party rights, including intellectual
              property or privacy rights.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            12. Modifications to the Services
          </h2>
          <p>
            We reserve the right to modify, suspend, or discontinue, temporarily
            or permanently, the Services or any features or portions thereof
            without prior notice. You agree that we will not be liable for any
            modification, suspension, or discontinuance of the Services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">13. Termination</h2>
          <ul className="list-disc ml-6">
            <li>
              <strong>By You:</strong> You may terminate your account at any
              time by following the instructions within the Services or by
              contacting us at support@everyone.world.
            </li>
            <li>
              <strong>By Us:</strong> We may suspend or terminate your access to
              the Services at our discretion, without notice, for any reason,
              including violation of these Terms, prolonged inactivity, or legal
              requirements.
            </li>
            <li>
              <strong>Effect of Termination:</strong> Upon termination, all
              licenses and rights granted to you in these Terms will immediately
              cease. We may, but are not obligated to, delete your User Content.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            14. Governing Law and Dispute Resolution
          </h2>
          <ul className="list-disc ml-6">
            <li>
              <strong>Governing Law:</strong> These Terms shall be governed by
              and construed in accordance with the laws of [Insert
              Jurisdiction], without regard to its conflict of law principles.
            </li>
            <li>
              <strong>Dispute Resolution:</strong> Any disputes arising under
              these Terms shall be resolved through binding arbitration in
              accordance with the rules of [Insert Arbitration Organization], or
              in the courts located in [Insert Jurisdiction], unless prohibited
              by applicable law.
            </li>
            <li>
              <strong>Time Limitation:</strong> You agree that any claim or
              cause of action arising out of or related to the use of the
              Services or these Terms must be filed within one (1) year after
              such claim or cause of action arose, or it will be permanently
              barred.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">15. Miscellaneous</h2>
          <h3 className="text-lg font-medium">Entire Agreement</h3>
          <p>
            These Terms, along with our Privacy Policy and Community Guidelines,
            constitute the entire agreement between you and us regarding the use
            of the Services and supersede any prior agreements.
          </p>

          <h3 className="text-lg font-medium mt-4">Severability</h3>
          <p>
            If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will remain in full force
            and effect.
          </p>

          <h3 className="text-lg font-medium mt-4">No Waiver</h3>
          <p>
            Our failure to enforce any right or provision of these Terms will
            not be deemed a waiver of such right or provision.
          </p>

          <h3 className="text-lg font-medium mt-4">Assignment</h3>
          <p>
            We may assign or transfer these Terms, in whole or in part, without
            restriction. You may not assign these Terms or transfer any rights
            to use the Services without our prior written consent.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            16. Contact Information
          </h2>
          <p>
            If you have any questions or concerns about these Terms or the
            Services, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> support@everyone.world
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsComponent;
