import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "components/context/AuthContext";
import { toast } from "react-toastify";

export default function DobPopup({ setAuthType }) {
  const [dateOfBirth, setDateOfBirth] = useState({
    day: "",
    month: "",
    year: "",
  });
  const { setAuth, auth } = useContext(AuthContext);

  useEffect(() => {
    if (auth.dateOfBirth) setDateOfBirth(auth.dateOfBirth);
  }, [auth]);

  function nextStep() {
    if (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year)
      return toast.error("Please select your birthdate");

    setAuth((prev) => {
      return {
        ...prev,
        dateOfBirth,
      };
    });
    setAuthType((prev) => ({ ...prev, authType: "useCase" }));
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12  max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full">
          <div className="flex  flex-col text-zinc-900 space-y-3">
            <div className="space-y-3">
              <div className="text-center pb-4  text-2xl font-poppins text-neutral-900 font-medium">
                Sign Up
              </div>
              <h1 className="text-xl">Your Birthdate</h1>
              <p className="text-slate-700 pb-6">
                You must be at least 13 years old to sign up.
              </p>

              <CustomDateOfBirth
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
              />
              <h1 className="pb-10">Your birthdate is not public</h1>
              <input
                type="button"
                onClick={nextStep}
                value="Next"
                aria-label="Sign Up"
                className="justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />
            </div>

            <div>
              <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
                By continuing, you agree to everyone’s{" "}
                <span className="text-black text-[15px] font-poppins">
                  {" "}
                  Terms of Service
                </span>{" "}
                and confirm that you have read everyone’s
                <span className="text-black font-poppins text-[15px]">
                  {" "}
                  Privacy Policy
                </span>
              </div>
              <div className="self-center mt-3 text-black text-[15px] font-poppins text-center">
                Already have an Account?{" "}
                <button
                  onClick={() =>
                    setAuthType((prev) => ({ ...prev, authType: "login" }))
                  }
                  className="text-rose-500 hover:underline"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomDateOfBirth({ dateOfBirth, setDateOfBirth }) {
  const handleOptionClick = (type, value) => {
    setDateOfBirth((prevState) => ({ ...prevState, [type]: value }));
  };

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  ).filter((year) => new Date().getFullYear() - year > 12);

  return (
    <div className="flex space-x-4">
      <Dropdown
        label="Day"
        options={days}
        selectedOption={dateOfBirth.day}
        onOptionClick={(value) => handleOptionClick("day", value)}
      />
      <Dropdown
        width={150}
        label="Month"
        options={months}
        selectedOption={dateOfBirth.month}
        onOptionClick={(value) => handleOptionClick("month", value)}
      />
      <Dropdown
        label="Year"
        options={years}
        selectedOption={dateOfBirth.year}
        onOptionClick={(value) => handleOptionClick("year", value)}
      />
    </div>
  );
}

function Dropdown({ label, options, selectedOption, onOptionClick, width }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ width }} className="relative w-24" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="w-full bg-gradient-to-br from-[#00FFAD] via-[#00DDFF] to-[#4093FF] rounded p-[1.5px]"
      >
        <span className="flex w-full bg-white rounded p-2 pl-6">
          {selectedOption || label}
        </span>
      </button>
      {isOpen && (
        <div className="absolute mt-1 w-full h-[200px] overflow-y-auto overflow-x-hidden bg-white border border-gray-200 rounded shadow-lg z-10">
          {options.map((option) => (
            <div
              key={option}
              onClick={() => {
                onOptionClick(option);
                setIsOpen(false);
              }}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
