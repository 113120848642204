import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "components/context/AuthContext";
import PhoneNumberInput from "./PhoneNumberInput";
import { countryCodes } from "./constants";
import { toast } from "react-toastify";
import axios from "axios";

export default function PhonePopup({ setAuthType, close }) {
  const { setAuth, auth } = useContext(AuthContext);
  const [code, setCode] = useState("");
  const [selectedCode, setSelectedCode] = useState(countryCodes[0].code);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setAuth((prev) => {
      return {
        ...prev,
        countryCode: selectedCode,
        phone: phoneNumber.split("-").join(""),
      };
    });
  }, [code, phoneNumber]);

  async function handleSubmit(e) {
    try {
      const token = window.localStorage.getItem("token");

      if (!token) {
        return;
      }

      const payload = {
        username: auth.username,
        dob: auth.dateOfBirth,
        phone: {
          number: auth.phone,
          code: auth.countryCode,
        },
      };

      setAuthType((prev) => ({ ...prev, authType: "loading" }));

      const res = await axios.put(
        process.env.REACT_APP_EVERYONE_BACKEND_URL +
          "/auth/update-user-profile",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.error) {
        toast.error(res.error);
      } else {
        close();
        toast.success("Profile updated successfully");
      }
    } catch (error) {
      if (!error.response) {
        toast.error("Network error, please try again");
      }

      //   toast.error(error.response.data?.message);
      toast.error("Something went wrong");
      setAuthType((prev) => ({ ...prev, authType: "phone" }));
    }
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12 max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full h-full">
          <div className="flex justify-between h-full flex-col text-zinc-900 space-y-3">
            <div className="space-y-3 mb-10">
              <div className="text-center pb-4  text-2xl font-poppins text-neutral-900 font-medium">
                Verify Phone Number
              </div>
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                selectedCode={selectedCode}
                setSelectedCode={setSelectedCode}
              />
              <div className="flex items-center justify-between border border-gray-300 rounded-md p-2">
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter 6 Digit Code"
                  className="w-20 flex-1 border-none focus:outline-none"
                />
                <button
                  type="button"
                  className="text-sm bg-pink-600 text-white rounded-md p-2 ml-2"
                >
                  Send Code
                </button>
              </div>
            </div>

            <div>
              <input
                type="button"
                onClick={handleSubmit}
                value="Submit"
                aria-label="Sign Up"
                className="justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />

              <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
                By continuing, you agree to everyone’s{" "}
                <span className="text-black text-[15px] font-poppins">
                  {" "}
                  Terms of Service
                </span>{" "}
                and confirm that you have read everyone’s
                <span className="text-black font-poppins text-[15px]">
                  {" "}
                  Privacy Policy
                </span>
              </div>
              <div className="self-center mt-3 text-black text-[15px] font-poppins text-center">
                Already have an Account?{" "}
                <button
                  onClick={() =>
                    setAuthType((prev) => ({ ...prev, authType: "login" }))
                  }
                  className="text-rose-500 hover:underline"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
