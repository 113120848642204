import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "components/context/AuthContext";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";

export default function UsernamePopup({ setAuthType, close }) {
  const { setAuth, auth } = useContext(AuthContext);

  function prevStep() {
    setAuthType((prev) => ({ ...prev, authType: "useCase" }));
  }

  async function handleChange(e) {
    // letters, numbers, underscores, and periods
    const regex = /^[a-zA-Z0-9_.]*$/;
    if (!regex.test(e.target.value)) {
      return toast.error(
        "Username can only contain letters, numbers, underscores, and periods"
      );
    }

    setAuth((prev) => {
      return {
        ...prev,
        username: e.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    try {
      const token = window.localStorage.getItem("token");

      if (!token) {
        return;
      }

      setAuthType((prev) => ({ ...prev, authType: "loading" }));

      if (e.target.value.length < 3)
        return toast.error("Username must be at least 3 characters long");
      const res = await axios.post(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/validate-username",
        {
          username: auth.username,
        }
      );

      if (res.data.error) {
        setAuthType((prev) => ({ ...prev, authType: "username" }));
        return toast.error(res.data.error);
      }

      const res1 = await axios.put(
        process.env.REACT_APP_EVERYONE_BACKEND_URL +
          "/auth/update-user-profile",
        auth,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res1.data.error) {
        toast.error(res1.data.error);
        setAuthType((prev) => ({ ...prev, authType: "username" }));
      } else {
        close();
        toast.success("Profile updated successfully");
        window.location.reload();
      }
    } catch (error) {
      if (!error.response) {
        toast.error("Network error, please try again");
      }

      //   toast.error(error.response.data?.message);
      setAuthType((prev) => ({ ...prev, authType: "login" }));

      toast.error("Something went wrong");
    }
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12 max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full h-full">
          <div className="flex justify-between h-full flex-col text-zinc-900 space-y-3">
            <div className="space-y-3">
              <div className="text-center  text-2xl font-poppins text-neutral-900 font-medium">
                <div className="flex items-center justify-center  text-center  text-2xl font-poppins text-neutral-900 font-medium">
                  <div
                    onClick={prevStep}
                    className="p-2 text-slate-700 cursor-pointer"
                  >
                    <IoIosArrowBack />
                  </div>
                  <h1>Sign Up</h1>
                </div>
              </div>
              <p className="text-slate-600 py-2 text-xl">
                Choose your username
              </p>
              <button className="w-full bg-gradient-to-br from-[#00FFAD] via-[#00DDFF] to-[#4093FF] rounded p-[1.5px]">
                <input
                  placeholder="@username"
                  value={auth.username}
                  onChange={handleChange}
                  className={`flex w-full bg-white rounded p-2 pl-6 border-none outline-none`}
                />
              </button>

              {/* username suggestions */}
              {auth.usernameSuggestions?.length > 0 && (
                <div className="flex items-start flex-col pb-10">
                  <h1>Suggestions</h1>
                  {auth.usernameSuggestions.map((username, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        setAuth((prev) => {
                          return {
                            ...prev,
                            username,
                          };
                        })
                      }
                      className="bg-white rounded p-2 pl-6"
                    >
                      {username}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="pt-4">
              <input
                type="button"
                onClick={handleSubmit}
                value="Submit"
                aria-label="Sign Up"
                className="justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />

              <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
                By continuing, you agree to everyone’s{" "}
                <span className="text-black text-[15px] font-poppins">
                  {" "}
                  Terms of Service
                </span>{" "}
                and confirm that you have read everyone’s
                <span className="text-black font-poppins text-[15px]">
                  {" "}
                  Privacy Policy
                </span>
              </div>
              <div className="self-center mt-3 text-black text-[15px] font-poppins text-center">
                Already have an Account?{" "}
                <button
                  onClick={() =>
                    setAuthType((prev) => ({ ...prev, authType: "login" }))
                  }
                  className="text-rose-500 hover:underline"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
