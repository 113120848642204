import React from "react";

const MarketOpportunity = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-8 bg-white">
      {/* Header Section */}
      <h1 className="text-3xl font-bold mb-8">Market Opportunity</h1>

      <div className="space-y-8">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">
            The Global Megaphone – A Multi-Billion Dollar Opportunity
          </h2>
          <p className="text-gray-700 mb-4">
            The Everyone Platform stands at the crossroads of multiple
            high-growth digital markets, creating a unified, groundbreaking
            space for users worldwide. As a global digital platform, it brings
            together social media, freelancing, streaming, and more, all under
            one digital roof.
          </p>
          <p className="text-gray-700 mb-6">
            The Everyone Platform is poised to revolutionize the digital
            landscape by uniting multiple high-growth markets into a single,
            comprehensive megaplatform. The Global Megaphone, The Everyone
            Platform. By integrating social media, freelancing, FAST (Free
            Ad-Supported Streaming TV) channels, The Everyone Platform offers an
            unparalleled opportunity for investors to capitalize on converging
            market trends.
          </p>
        </section>

        {/* Market Sections */}
        <section className="space-y-12">
          {/* 1. Social Media Market */}
          <div>
            <h3 className="text-xl font-semibold mb-4">
              1. The Booming Social Media Market
            </h3>
            <p className="text-gray-700 mb-4">
              The global social media market has been experiencing exponential
              growth:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-700">
              <li>
                Market Size: Valued at approximately $159 billion in 2021,
                projected to reach $223 billion by 2027, growing at a CAGR of
                7.4%.
              </li>
              <li>
                User Base: Over 4.5 billion people worldwide use social media as
                of 2021, accounting for more than half of the global population.
              </li>
              <li>
                Engagement: Users spend an average of 2.5 hours per day on
                social media platforms.
              </li>
            </ul>
            <div className="mt-4 bg-gray-50 p-4 rounded">
              <h4 className="font-semibold mb-2">Opportunity with Everyone</h4>
              <p className="text-gray-700">
                Everyone taps into this vast user base by offering a global
                sequential channel stream where every post reaches a worldwide
                audience, enhancing visibility and engagement beyond traditional
                algorithms.
              </p>
            </div>
          </div>

          {/* 2. Freelance Market */}
          <div>
            <h3 className="text-xl font-semibold mb-4">
              2. The Rapidly Growing Freelance Market
            </h3>
            <p className="text-gray-700 mb-4">
              The freelance economy is reshaping the workforce:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-700">
              <li>
                Market Size: The global freelance market generated $1.2 trillion
                in revenue in the U.S. alone in 2020.
              </li>
              <li>
                Workforce Shift: By 2027, freelancers are expected to make up
                50.9% of the U.S. workforce.
              </li>
              <li>
                Demand for Platforms: Growing need for efficient, cost-effective
                platforms connecting freelancers with clients.
              </li>
            </ul>
            <div className="mt-4 bg-gray-50 p-4 rounded">
              <h4 className="font-semibold mb-2">Opportunity with Everyone</h4>
              <p className="text-gray-700">
                Everyone provides freelancers with a zero-fee marketplace,
                allowing them to keep 100% of their earnings—a significant
                advantage over competitors charging 10-20% service fees.
              </p>
            </div>
          </div>

          {/* Continue with remaining sections */}
          {/* 3. FAST Channels */}
          <div>
            <h3 className="text-xl font-semibold mb-4">
              3. The Emergence of FAST Channels
            </h3>
            <p className="text-gray-700 mb-4">
              Free Ad-Supported Streaming TV (FAST) is a rapidly expanding
              segment:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-700">
              <li>
                Market Growth: Projected to grow from $2.1 billion in 2021 to
                $4.1 billion by 2023.
              </li>
              <li>
                Consumer Preference: Increasing cord-cutting trends lead
                consumers to seek free, ad-supported streaming options.
              </li>
              <li>
                Content Demand: High demand for diverse, real-time content.
              </li>
            </ul>
          </div>

          {/* 4. Unified Communication */}
          <div>
            <h3 className="text-xl font-semibold mb-4">
              4. The Unified Communication Market
            </h3>
            <ul className="list-disc pl-6 space-y-3 text-gray-700">
              <li>
                Market Size: Valued at $78.33 billion in 2020, expected to reach
                $344.84 billion by 2028.
              </li>
              <li>
                Remote Work: Rise of remote work accelerates adoption of unified
                communication tools.
              </li>
              <li>
                User Demand: Preference for platforms consolidating messaging,
                video, and collaboration tools.
              </li>
            </ul>
          </div>

          {/* 5. Creator Economy */}
          <div>
            <h3 className="text-xl font-semibold mb-4">
              5. The Creator Economy Expansion
            </h3>
            <ul className="list-disc pl-6 space-y-3 text-gray-700">
              <li>Market Size: Valued at over $104 billion in 2022.</li>
              <li>
                Number of Creators: Over 50 million people consider themselves
                creators, with 2 million making six-figure incomes.
              </li>
              <li>
                Monetization Challenges: Creators face high platform fees and
                limited monetization tools.
              </li>
            </ul>
          </div>

          {/* Additional sections... */}
          <div className="space-y-8">
            {/* 6-10... */}

            <div>
              <h3 className="text-xl font-semibold mb-4">
                6. The Rise of Blockchain Technology
              </h3>
              <p className="text-gray-700 mb-4">
                Blockchain is integral across industries:
              </p>
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li>
                  Market Growth: Expected to reach $69.04 billion by 2027,
                  growing at a CAGR of 56.1%.
                </li>
                <li>
                  Demand for Transparency: Users demand transparency and
                  security in digital transactions and content ownership.
                </li>
              </ul>
              <div className="mt-4 bg-gray-50 p-4 rounded">
                <h4 className="font-semibold mb-2">
                  Opportunity with Everyone
                </h4>
                <p className="text-gray-700">
                  The Everyone Blockchain ensures secure recording of each
                  channel and interaction, offering transparency and data
                  integrity, enhancing user trust and platform credibility.
                </p>
              </div>
            </div>

            {/* 7. Multi-Platform Integration */}
            <div>
              <h3 className="text-xl font-semibold mb-4">
                7. Multi-Platform Integration Demand
              </h3>
              <p className="text-gray-700 mb-4">
                Efficiency in content distribution is key:
              </p>
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li>
                  Content Distribution: 66% of users prefer content shared
                  across multiple platforms.
                </li>
                <li>
                  Time Savings: Multi-platform posting saves creators
                  significant time, allowing focus on content creation.
                </li>
              </ul>
              <div className="mt-4 bg-gray-50 p-4 rounded">
                <h4 className="font-semibold mb-2">
                  Opportunity with Everyone
                </h4>
                <p className="text-gray-700">
                  Everyone's multi-platform posting enables users to distribute
                  content across various social networks and communication
                  platforms effortlessly, amplifying reach and engagement.
                </p>
              </div>
            </div>

            {/* 8. Advertising Market Shifts */}
            <div>
              <h3 className="text-xl font-semibold mb-4">
                8. Advertising Market Shifts
              </h3>
              <p className="text-gray-700 mb-4">
                Advertising models are evolving:
              </p>
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li>
                  Digital Ad Spend: Projected to reach $526 billion globally by
                  2024.
                </li>
                <li>
                  Consumer Preferences: 80% of consumers prefer engaging with
                  authentic content over traditional ads.
                </li>
              </ul>
              <div className="mt-4 bg-gray-50 p-4 rounded">
                <h4 className="font-semibold mb-2">
                  Opportunity with Everyone
                </h4>
                <p className="text-gray-700">
                  Everyone's Spotlight ads integrate seamlessly into the user
                  experience, offering a non-intrusive advertising model that
                  aligns with consumer preferences, enhancing ad effectiveness
                  and user satisfaction.
                </p>
              </div>
            </div>

            {/* 9. Global Connectivity */}
            <div>
              <h3 className="text-xl font-semibold mb-4">
                9. Global Connectivity and Inclusion
              </h3>
              <p className="text-gray-700 mb-4">
                Digital inclusion is expanding:
              </p>
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li>
                  Internet Penetration: Over 63% of the global population is
                  online.
                </li>
                <li>
                  Emerging Markets: Significant user growth in emerging markets
                  represents vast untapped potential.
                </li>
              </ul>
              <div className="mt-4 bg-gray-50 p-4 rounded">
                <h4 className="font-semibold mb-2">
                  Opportunity with Everyone
                </h4>
                <p className="text-gray-700">
                  Designed for worldwide access, Everyone fosters a truly global
                  audience, promoting cross-cultural engagement and tapping into
                  emerging markets.
                </p>
              </div>
            </div>

            {/* 10. Sustainable Business Model */}
            <div>
              <h3 className="text-xl font-semibold mb-4">
                10. Sustainable and Scalable Business Model
              </h3>
              <p className="text-gray-700 mb-4">
                Platforms need sustainable growth potential:
              </p>
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li>
                  Scalability: Ability to scale globally without significant
                  increases in operational costs is highly attractive.
                </li>
                <li>
                  User Retention: High-value services at no cost enhance user
                  retention and platform longevity.
                </li>
              </ul>
              <div className="mt-4 bg-gray-50 p-4 rounded">
                <h4 className="font-semibold mb-2">
                  Opportunity with Everyone
                </h4>
                <p className="text-gray-700">
                  Everyone's zero-fee model, combined with premium features and
                  blockchain technology, offers a scalable platform with high
                  user retention potential, making it an attractive investment
                  for long-term growth.
                </p>
              </div>
            </div>
            {/* Conclusion */}
            <div className="space-y-6">
              <p className="text-gray-800 leading-relaxed">
                The convergence of these high-growth markets presents a unique
                and lucrative opportunity for investors. The Everyone Platform,
                as <span className="font-semibold">The Global Megaphone</span>,
                stands at the intersection of social media, freelancing,
                streaming, unified communications, and blockchain technology. By
                addressing the needs of creators, freelancers, and users seeking
                global engagement without barriers, Everyone is poised to
                capture significant market share across these sectors.
              </p>

              <p className="text-gray-800 leading-relaxed">
                Investing in Everyone means backing a platform that not only
                meets current market demands but is also strategically
                positioned for future growth as these markets continue to
                expand. With its innovative features, user-first approach, and
                commitment to empowering creators and users alike, Everyone
                represents a compelling opportunity for investors seeking to be
                part of the next big wave in digital transformation.
              </p>

              <p className="text-xl font-semibold text-center mt-8">
                Join us in amplifying voices globally. Invest in The Global
                Megaphone, The Everyone Platform.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MarketOpportunity;
