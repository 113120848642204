import React from "react";

const RoadmapMilestones = () => {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-bold mb-6">Roadmap & Milestones</h1>

      <p className="mb-4">
        With a foundation built on years of focused development and
        forward-thinking design, <span className="font-semibold">Everyone</span>{" "}
        has evolved into an all-in-one platform that combines social networking,
        blockchain technology, and universal posting capabilities. Every stage
        of <span className="font-semibold">Everyone</span>'s creation has been
        planned to provide users with a secure, zero-fee, globally accessible
        platform that seamlessly connects people across networks. Our roadmap
        below outlines both our accomplishments and the key milestones that lie
        ahead, as we set ambitious targets to expand our user base from 1
        million to 1 billion.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Phase 1: Research and Development (Completed)
      </h2>
      <p className="mb-4">
        To lay the foundation for{" "}
        <span className="font-semibold">Everyone</span>, we conducted extensive
        R&D to develop the platform’s core features and unique value
        propositions, focusing on:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">Zero-Fee Model:</span> Designed to
          allow freelancers and sellers to retain 100% of their earnings, a
          unique benefit in the digital gig and freelance markets.
        </li>
        <li>
          <span className="font-semibold">The Everyone Stream:</span> A
          transparent, chronological global feed that provides real-time
          visibility to every user, bypassing the limitations of algorithmic
          filtering.
        </li>
        <li>
          <span className="font-semibold">Blockchain-Backed Trust:</span>{" "}
          Leveraging blockchain technology to secure and verify every
          transaction, ensuring transparency, security, and accountability.
        </li>
      </ul>
      <p className="mb-8">
        This phase was crucial for creating a solid technical and conceptual
        framework that would enable us to redefine the digital communication and
        freelance marketplaces.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Phase 2: Platform Development and Initial Launch (In Progress)
      </h2>
      <p className="mb-8">
        Our current platform is live in its initial website form, with basic
        functionalities ready for use. This phase marks the entry point where
        users can begin posting in the{" "}
        <span className="font-semibold">Everyone stream</span> and experience
        the core zero-fee structure. While the platform is operational,
        additional features are under active development to enhance user
        experience and functionality. This stage allows us to collect early
        feedback and fine-tune the system for greater scalability.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Phase 3: Beta Testing and Iteration (Ongoing)
      </h2>
      <p className="mb-4">
        To ensure that <span className="font-semibold">Everyone</span> meets
        high standards of usability and engagement, we are gathering a group of
        early adopters for rigorous beta testing. This third round of testing
        will provide valuable insights into user behavior, interface
        preferences, and feature performance, helping us further refine the
        platform:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>
          <span className="font-semibold">Objective:</span> Identify and address
          any friction points, optimize user interface design, and ensure
          seamless integration of all features.
        </li>
        <li>
          <span className="font-semibold">Outcome:</span> A user-friendly,
          stable platform that supports high levels of engagement and retention.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        Phase 4: Media Outreach and Public Rollout (Upcoming)
      </h2>
      <p className="mb-4">
        With a refined and tested platform, we will transition to a public
        rollout with a targeted media campaign to drive awareness and initial
        growth. This outreach will emphasize{" "}
        <span className="font-semibold">Everyone</span>'s unique strengths,
        positioning it as a go-to platform for freelancers, sellers, and global
        communicators:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>
          <span className="font-semibold">Media Strategy:</span> Highlight{" "}
          <span className="font-semibold">Everyone</span>’s zero-fee model,
          chronological stream, and blockchain-backed security to differentiate
          it from competitors.
        </li>
        <li>
          <span className="font-semibold">Target:</span> Build initial momentum
          and create a strong user base by generating widespread interest
          through press, influencers, and strategic partnerships.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        Phase 5: User Acquisition Milestones
      </h2>
      <p className="font-semibold mb-2">Goal: 1 Million Active Users</p>
      <p className="mb-4">
        Our first milestone is to attract 1 million active users. This will be
        achieved by:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>
          <span className="font-semibold">
            Cross-Promotions with Influencers:
          </span>{" "}
          Establishing partnerships with influential personalities to promote{" "}
          <span className="font-semibold">Everyone</span>’s unique features and
          benefits to diverse audiences.
        </li>
        <li>
          <span className="font-semibold">User Incentives:</span> Offering early
          user badges and benefits to incentivize initial engagement and
          encourage community growth.
        </li>
      </ul>

      <p className="font-semibold mb-2">Goal: 50 Million Active Users</p>
      <p className="mb-4">
        Once we reach 1 million, our next target is 50 million users. This phase
        focuses on:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>
          <span className="font-semibold">Expanded Feature Set:</span> Adding
          advanced features such as analytics tools for creators, enhanced gig
          management options, and multi-format content support.
        </li>
        <li>
          <span className="font-semibold">Global Expansion:</span> Localizing
          the platform in key international markets to appeal to a diverse
          global audience, leveraging regional marketing strategies.
        </li>
      </ul>

      <p className="font-semibold mb-2">Goal: 100 Million Active Users</p>
      <p className="mb-4">
        With a larger user base, we aim to reach 100 million users by scaling
        our community through:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>
          <span className="font-semibold">Targeted Partnerships:</span>{" "}
          Collaborating with international brands and organizations to drive
          adoption across various professional and creative communities.
        </li>
        <li>
          <span className="font-semibold">
            Enhanced Cross-Platform Integration:
          </span>{" "}
          Strengthening our Universal Posting capabilities to enable seamless
          integration with a growing number of social and decentralized
          platforms, maximizing user reach.
        </li>
      </ul>

      <p className="font-semibold mb-2">Goal: 1 Billion Active Users</p>
      <p className="mb-4">
        Our long-term vision is to reach 1 billion active users, establishing{" "}
        <span className="font-semibold">Everyone</span> as the leading global
        platform for transparent and inclusive communication. This milestone
        will be achieved by:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <span className="font-semibold">Continuous Feature Innovation:</span>{" "}
          Regularly updating the platform with cutting-edge features, such as
          AI-driven content management and augmented reality tools, to keep pace
          with technological trends.
        </li>
        <li>
          <span className="font-semibold">Strategic Alliances:</span> Forming
          alliances with major technology and media companies to embed{" "}
          <span className="font-semibold">Everyone</span>’s features across
          different digital ecosystems.
        </li>
        <li>
          <span className="font-semibold">
            Sustainable Monetization Models:
          </span>{" "}
          While maintaining the zero-fee structure for user transactions, we
          will explore additional revenue streams through brand partnerships and
          spotlighted content, keeping the user experience free from traditional
          ads and fees.
        </li>
      </ul>
    </div>
  );
};

export default RoadmapMilestones;
