import React from "react";
import Dots from "./Dots";
import Stars from "./Stars";

export default function BgAnimation() {
  return (
    <div className="max-h-screen overflow-y-hidden z-50">
      <div className="max-lg:hidden">
        <Dots dotImage="images/dots/d1.png" numDots={200} />
        <Stars numDots={100} />
      </div>
      <div className="max-sm:hidden lg:hidden">
        <Dots dotImage="images/dots/d1.png" numDots={100} />
        <Stars numDots={50} />
      </div>{" "}
      <div className="sm:hidden">
        <Dots dotImage="images/dots/d1.png" numDots={75} />
        <Stars numDots={30} />
      </div>
      <img
        className="fixed top-0 left-0 w-screen h-screen object-cover"
        src="images/landing/bg.jpg"
        alt=""
      />
      <img
        className="fixed z-10 md:w-[800px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4 md:-translate-y-1/2"
        src="images/landing/h-glow.png"
        alt=""
      />
      <img
        className="fixed w-[2800px] h-[2000px] object-cover z-40 -bottom-[1350px] left-1/2 transform -translate-x-1/2"
        src="images/landing/globe-shadow.png"
        alt=""
      />
      <img
        className="fixed w-[3000px] h-[3000px] md:w-[3800px] md:h-[3000px] object-cover z-40 -bottom-[2700px] md:-bottom-[2600px] left-1/2 transform -translate-x-1/2"
        src="images/landing/globe.png"
        alt=""
      />{" "}
    </div>
  );
}
