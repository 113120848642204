import React from "react";

const FinancialProjections = () => {
  return (
    <div className="p-8  text-gray-800 font-sans">
      <h1 className="text-4xl font-bold text-center mb-8">
        Financial Projections
      </h1>

      <section className="mb-8">
        <p className="text-lg mb-4">
          Below, we outline our revenue streams, user growth, cost structure,
          profitability timeline, and our long-term financial goals.
        </p>
      </section>

      {/* Revenue Streams & Projections */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          1. Revenue Streams & Projections
        </h2>
        <p className="mb-4">
          <span className="font-semibold">Everyone</span> generates revenue
          through a combination of Spotlighted Posts, Featured Channels, Brand
          Partnerships, and Sponsored Content. Our financial projections
          anticipate significant growth as our user base expands:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>1 Million Users: Projected annual revenue of $1.1 million.</li>
          <li>50 Million Users: Projected annual revenue of $42.5 million.</li>
          <li>100 Million Users: Projected annual revenue of $70 million.</li>
          <li>
            1 Billion Users by Year 7: Projected annual revenue of $600 million.
          </li>
        </ul>
        <p>
          Revenue from premium features is expected to grow consistently, with
          users purchasing enhanced visibility and brand collaborations
          increasing as we scale.
        </p>
      </section>

      {/* User Growth Trajectory */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          2. User Growth Trajectory
        </h2>
        <p className="mb-4">Our strategic growth targets are as follows:</p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Year 1: 1 million users</li>
          <li>Year 2: 10 million users</li>
          <li>Year 3: 50 million users</li>
          <li>Year 4: 100 million users</li>
          <li>Year 7: 1 billion users</li>
        </ul>
        <p>
          Our goal is to foster an engaged community with a target of 80%
          monthly active users, ensuring a vibrant and continuously active user
          base that supports strong monetization.
        </p>
      </section>

      {/* Cost Structure Overview */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          3. Cost Structure Overview
        </h2>
        <p className="mb-4">
          The primary costs for <span className="font-semibold">Everyone</span>{" "}
          are storage and bandwidth, accounting for roughly 40% of our revenue,
          with bandwidth anticipated to be a larger expense as the platform
          grows. Additional key expenses include platform development,
          marketing, and customer support. Projected total annual costs are as
          follows:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>1 Million Users: Approximately $1.94 million annually</li>
          <li>50 Million Users: Approximately $62.5 million annually</li>
          <li>100 Million Users: Approximately $118.5 million annually</li>
          <li>1 Billion Users: Approximately $1.09 billion annually</li>
        </ul>
        <p>
          As we scale, we aim to leverage economies of scale to manage per-user
          costs efficiently, enhancing our platform's financial resilience.
        </p>
      </section>

      {/* Path to Profitability */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          4. Path to Profitability
        </h2>
        <p>
          Our break-even point is projected to occur as user growth, premium
          feature adoption, and cost management align. Profitability will be
          driven by a combination of growing revenue streams, optimized storage
          and bandwidth expenses, and improved operational efficiencies.
        </p>
      </section>

      {/* Initial Funding Requirements */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          5. Initial Funding Requirements
        </h2>
        <p>
          To reach our first key milestones, we are seeking an initial
          investment of $2 million, designed to support platform growth and
          visibility as we build a global presence. This funding will be
          strategically allocated to support user acquisition and early-stage
          brand partnerships.
        </p>
      </section>

      {/* Financial Ratios & User Metrics */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          6. Financial Ratios & User Metrics
        </h2>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>
            <span className="font-semibold">Revenue per User (ARPU)</span> grows
            as user engagement increases, with projected ARPU ranging from $1.10
            at 1 million users to $0.60 at 1 billion users, as users adopt
            premium features.
          </li>
          <li>
            <span className="font-semibold">Cost per User (CPU)</span> decreases
            gradually from $1.94 at 1 million users to $1.09 at 1 billion users,
            benefiting from operational efficiencies and economies of scale as
            our user base expands.
          </li>
        </ul>
      </section>

      {/* Long-Term Financial Goals */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          7. Long-Term Financial Goals
        </h2>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Revenue: Projected $600 million annually by Year 7.</li>
          <li>User Base: Expected 1 billion global users.</li>
          <li>
            Return on Investment (ROI): Significant growth-driven ROI for early
            investors.
          </li>
        </ul>
      </section>

      {/* Risk Management & Sensitivity */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          8. Risk Management & Sensitivity
        </h2>
        <p className="mb-4">
          Our financial model includes risk analyses focusing on:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>
            <span className="font-semibold">User Growth Sensitivity:</span>{" "}
            Ensuring continued growth and engagement while adapting to varying
            user acquisition rates.
          </li>
          <li>
            <span className="font-semibold">Conversion & Spend Rates:</span>{" "}
            Continuously optimizing premium features to increase average spend
            per user.
          </li>
          <li>
            <span className="font-semibold">Cost Management:</span> Leveraging
            scalable storage and bandwidth solutions to efficiently manage
            variable costs.
          </li>
        </ul>
        <p>
          Our team has implemented mitigation strategies to address potential
          market and operational risks and ensure the platform's ongoing
          stability and scalability.
        </p>
      </section>
    </div>
  );
};

export default FinancialProjections;
