import React from "react";

const PrivacyPolicy = () => {
  const platformFeatures = [
    "A chronological feed for sharing content.",
    "Encrypted messaging and video meetings.",
    "Gig postings and item sales.",
    "Dating features.",
    "A privacy-focused search browser.",
    "Blockchain integration to record channel ownership and content hashes.",
  ];

  const accountInfo = [
    { field: "Username", description: "To identify you on the platform." },
    { field: "Password", description: "To secure your account." },
    {
      field: "Email Address",
      description: "For account verification and communication.",
    },
    {
      field: "Phone Number",
      description:
        "For enhanced security features like two-factor authentication.",
    },
    {
      field: "Date of Birth",
      description:
        "To verify that you meet the minimum age requirement of 18 years.",
    },
  ];

  const optionalInfo = [
    "Profile Picture",
    "Bio",
    "Location",
    "Interests",
    "Occupation",
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm p-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2">
            Privacy Policy for The Everyone Platform
          </h1>
          <p className="text-gray-600">Effective Date: June 5, 2024</p>
        </div>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            Welcome to The Everyone Platform
          </h2>
          <p className="text-gray-700 mb-4">
            Your privacy is important to us. This Privacy Policy explains how
            The Everyone Platform ("we," "us," or "our") collects, uses,
            discloses, stores, and protects your personal information when you
            use our services. Please read this policy carefully to understand
            our practices regarding your information and how we will treat it.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700 mb-4">
            The Everyone Platform is an all-in-one service that offers:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            {platformFeatures.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <p className="mt-4 text-gray-700">
            By using our services, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            2. Information We Collect
          </h2>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">
              2.1. Account Information
            </h3>
            <p className="text-gray-700 mb-3">
              When you create an account, we collect:
            </p>
            <ul className="space-y-2">
              {accountInfo.map(({ field, description }, index) => (
                <li key={index} className="text-gray-700">
                  <span className="font-medium">{field}</span>: {description}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">
              2.2. Optional Profile Information
            </h3>
            <p className="text-gray-700 mb-3">
              You may choose to provide additional information to personalize
              your profile:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              {optionalInfo.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
            <p className="mt-4 text-gray-700">
              Providing this information is optional and helps you connect with
              other users.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
