"use client";

import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function TwitterCallback() {
  let [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      window.localStorage.setItem("token", token);
      window.location.href = "/";
    }
  }, []);

  return (
    <Backdrop open={true}>
      <CircularProgress sx={{ color: "white" }} />
    </Backdrop>
  );
}
