import { Divider } from "@mui/material";

import React, { useContext, useEffect } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "components/context/AuthContext";
import ReactFacebookLogin from "react-facebook-login";

export default function LoginPopup({ setAuthType, close }) {
  const { setAuth } = useContext(AuthContext);
  const googleLogin = useGoogleLogin({
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      try {
        setAuthType((prev) => ({ ...prev, authType: "loading" }));
        const res = await axios.post(
          process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/signin/google",
          {
            access_token: tokenResponse.access_token,
          }
        );
        console.log(res);
        if (res.data.token) {
          setAuth({
            token: res.data.token,
            user: res.data.user,
            usernameSuggestions: res.data.usernameSuggestions,
            isLoggedIn: true,
          });

          window.localStorage.setItem("token", res.data.token);
          toast.success("Login successful");
        }

        if (res.data.register) {
          setAuthType((prev) => ({ ...prev, authType: "register" }));
          toast.error("User does not exist, please register");
        } else if (res.data.nextStep) {
          setAuthType((prev) => ({ ...prev, authType: "dob" }));
        } else {
          close();
        }
      } catch (error) {
        setAuthType((prev) => ({ ...prev, authType: "login" }));
        if (!error.response) toast.error("Network error, please try again");
        else toast.error(error.response?.data.message);
      }
    },
  });

  const facebookLogin = async (response) => {
    console.log(response);
    try {
      setAuthType((prev) => ({ ...prev, authType: "loading" }));
      const res = await axios.post(
        process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/signin/facebook",
        {
          email: response.email,
          name: response.name,
          provider: "facebook",
          providerId: response.id,
          picture: response.picture.data.url,
        }
      );
      console.log(res);
      if (res.data.token) {
        setAuth({
          token: res.data.token,
          user: res.data.user,
          usernameSuggestions: res.data.usernameSuggestions,
          isLoggedIn: true,
        });

        window.localStorage.setItem("token", res.data.token);
        toast.success("Login successful");
      }

      if (res.data.register) {
        setAuthType((prev) => ({ ...prev, authType: "register" }));
        toast.error("User does not exist, please register");
      } else if (res.data.nextStep) {
        setAuthType((prev) => ({ ...prev, authType: "dob" }));
      } else {
        close();
      }
    } catch (error) {
      setAuthType((prev) => ({ ...prev, authType: "login" }));
      if (!error.response) toast.error("Network error, please try again");
      else toast.error(error.response?.data.message);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12  max-w-[850px] overflow-y-auto flex items-center justify-center z-50`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col  text-center text-zinc-900 max-md:mt-10 space-y-3">
            <div className="pb-2 self-center text-2xl font-poppins text-neutral-900 font-medium">
              Log in
            </div>
            <div
              className="flex px-10 py-3 w-full bg-white rounded-lg border border-solid border-stone-300 max-md:px-5 cursor-pointer"
              onClick={() =>
                setAuthType((prev) => ({ ...prev, authType: "phone_login" }))
              }
            >
              <div className="flex gap-3 font-poppins text-[17px]">
                <img
                  loading="lazy"
                  src="/assets/images/auth/user.png"
                  className="shrink-0 my-auto aspect-square w-[15px] h-[15px]"
                />
                <div>Phone, email or username</div>
              </div>
            </div>
            <div
              className="flex px-14 py-3 w-full bg-white rounded-lg border border-solid border-stone-300 max-md:px-5 cursor-pointer"
              onClick={googleLogin}
            >
              <div className="flex gap-3.5 pl-3 font-poppins text-[17px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae3bdd38ec93a5855a1aa2f741c3aaa138343cf11d2701decbfe5118e38a07bc?apiKey=bef308c750544a03a3bf2b5acb540117&"
                  className="shrink-0 my-auto aspect-square w-[23px]"
                />
                <div>Login with Google</div>
              </div>
            </div>
            <FacebookLogin
              appId="2277975315871326"
              fields="name,email,picture"
              scope="public_profile"
              callback={facebookLogin}
              render={(renderProps) => (
                <div
                  className="flex px-14 py-3 w-full bg-white rounded-lg border border-solid border-stone-300 max-md:px-5 cursor-pointer"
                  onClick={renderProps.onClick}
                >
                  <div className="flex gap-3.5 pl-3 font-poppins text-[17px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/77e4d3bd900415688100a9fe3e7c01eb0f381fe71e8c8ab8a74fc74bf30d9e70?apiKey=bef308c750544a03a3bf2b5acb540117&"
                      className="shrink-0 my-auto w-6 aspect-square"
                    />
                    <div>Login with Facebook</div>
                  </div>
                </div>
              )}
            />

            {/* <div className="flex px-14 py-3 w-full bg-white rounded-lg border border-solid border-stone-300 max-md:px-5 cursor-pointer">
              <div className="flex gap-3.5 pl-3 font-poppins text-[17px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/269d9ae6ceff90b90a70737f35d075101deff3363c88f8121449cd904322578d?apiKey=bef308c750544a03a3bf2b5acb540117&"
                  className="shrink-0 self-start w-5 aspect-[0.8]"
                />
                <div>Login with Apple</div>
              </div>
            </div> */}
            <a
              href={`${process.env.REACT_APP_EVERYONE_BACKEND_URL}/auth/twitter`}
              className="cursor-pointer flex px-14 py-3 w-full bg-white rounded-lg border border-solid border-stone-300 max-md:px-5"
            >
              <div className="flex gap-3.5 pl-3 font-poppins text-[17px]">
                <img
                  loading="lazy"
                  src="/assets/images/auth/x.png"
                  className="shrink-0 my-auto w-6 aspect-square"
                />
                <div>Login with X</div>
              </div>
            </a>

            <Divider>
              <span className="text-slate-400">or</span>
            </Divider>

            <input
              onClick={() => close()}
              type="button"
              value="Continue as Guest"
              aria-label="Sign Up"
              className="justify-center items-center px-16 py-3.5 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
            />

            <div className="justify-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
              By continuing, you agree to everyone’s{" "}
              <span className="text-black text-[15px] font-poppins">
                {" "}
                Terms of Service
              </span>{" "}
              and confirm that you have read everyone’s
              <span className="text-black font-poppins text-[15px]">
                {" "}
                Privacy Policy
              </span>
            </div>
            <div className="self-center mt-3 text-black text-[15px] font-poppins">
              Don’t have an Account?{" "}
              <button
                onClick={() =>
                  setAuthType((prev) => ({ ...prev, authType: "register" }))
                }
                className="text-rose-500 hover:underline"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
