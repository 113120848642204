import React, { useState } from "react";

const faqs = [
  {
    question: "What is Everyone? Why is the domain everyone.world so valuable?",
    answer: `Everyone is the all-in-one, fair-access platform where users can connect, share, and engage in real-time, across a single, global stream. The domain name, everyone.world, is instantly memorable and speaks directly to our mission: a place where the entire world can connect, without barriers or complex algorithms.
    
    Our name reflects a fundamental goal: fair, sequential visibility for every post, giving each user the opportunity to reach the whole world on a platform that prioritizes transparency and accessibility.`,
  },
  {
    question: "How will Everyone reach people and build its user base?",
    answer: `To launch The Everyone Platform into the global consciousness, we're planning a large-scale marketing campaign across all major channels. This includes billboards, TV commercials, social media ads, and other digital platforms, all pointing to everyone.world. Our messaging will emphasize the platform’s accessibility and fairness, positioning Everyone as the place where the world can connect for free.`,
  },
  {
    question: "How does Everyone generate revenue?",
    answer: `Our revenue model is straightforward and user-driven. Users have the option to Spotlight their posts for $29.99. This means that, when a user wants extra visibility, they can pay to have their post appear more frequently to a broader audience in the global stream. Additionally, users can Feature their channels to boost engagement and visibility further.
    
    We also plan to generate revenue through sponsored content by partnering with brands that want to reach Everyone’s audience. These features allow us to monetize without traditional ads, offering a cleaner, more engaging experience.`,
  },
  {
    question: "How does user growth affect revenue?",
    answer: `As the user base grows, so does demand for Spotlighted and Featured posts, creating a reliable stream of recurring revenue. Higher engagement rates directly increase the number of users willing to Spotlight posts and Feature channels, scaling revenue as the platform expands. This natural growth in demand supports both short-term revenue and long-term sustainability.`,
  },
  {
    question: "How does cross-platform posting work on Everyone?",
    answer: `Everyone lets users share their content broadly with a single action. When a user posts on Everyone, they can choose to cross-post that content to their other social media accounts and across decentralized networks. This means that users can stay connected to multiple communities and audiences without managing separate posts, simplifying their online presence in a way that’s currently unmatched.`,
  },
  {
    question: "How does Everyone help users get seen?",
    answer: `Every post on Everyone is assigned a unique channel number, making it part of a global, chronological stream. This ensures that content is displayed fairly and sequentially, without any complex algorithms. Everyone prioritizes fresh, active content, allowing all users, new and returning, to reach more people in a balanced and open environment.`,
  },
  {
    question: "Why will users choose Everyone?",
    answer: `Everyone is designed to simplify and empower users’ online experience, offering them:

    - **Fee-Free Gig Listings**: Users can offer and find gigs without paying platform fees, making Everyone ideal for freelancers, small businesses, and entrepreneurs looking to connect with clients.
    - **Cross-Platform Visibility**: Posting once on Everyone allows users to simultaneously share across traditional social platforms and decentralized networks, enhancing their reach.
    - **All-in-One Productivity**: From sharing documents to managing projects and collaborating with others, Everyone is built to be the ultimate hub for both personal and professional activities.`,
  },
  {
    question:
      "What role does blockchain play in Everyone? Is it a cryptocurrency?",
    answer: `Blockchain is used exclusively to record each post’s unique channel number and owner information, adding security and transparency. Everyone is not a cryptocurrency platform, and we don’t facilitate token trading. However, our Decentralized Autonomous Organization (DAO) allows users to participate in platform decisions through voting with the DAO tokens that the user has accumulated through their use of the platform. In the future, we may explore converting the DAO to a coin if regulatory conditions allow, but we are committed to compliance with U.S. and international regulations.`,
  },
  {
    question: "What is Everyone’s DAO, and why is it important?",
    answer: `Our DAO enables users to have a direct influence on the platform’s direction by voting on decisions, from new features to community guidelines. This transparent governance model fosters loyalty and community ownership, ensuring that Everyone remains aligned with its users’ needs and values.`,
  },
  {
    question: "How does Everyone ensure a safe user environment?",
    answer: `We actively monitor content through AI-driven tools combined with human oversight to keep Everyone free from harmful or inappropriate material. This approach allows us to maintain a safe, inclusive space that users of all ages and backgrounds can trust.`,
  },
  {
    question: "What are Everyone’s growth targets?",
    answer: `We aim to reach 1 million users in our first year, growing to 50 million by year 2 and 100 million users by Year 4. This growth will be driven by a combination of our large-scale marketing campaigns, partnerships with top media and entertainment brands, and cross-platform connectivity, which attracts and retains a broad, engaged audience.`,
  },
  {
    question: "How does Everyone support user privacy?",
    answer: `Privacy is foundational at Everyone. We comply with global data protection standards, including GDPR and CCPA, ensuring that all user data is encrypted and stored securely. Privacy settings empower users to control data sharing according to their preferences, building a trusted experience.`,
  },
];

export default function FAQ() {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-3xl font-bold mb-8 text-center">
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
}

function FAQItem({ faq }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border border-gray-300 p-4 rounded-lg">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left font-semibold text-lg flex justify-between items-center"
      >
        <span>{faq.question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-700">{faq.answer}</p>}
    </div>
  );
}
