import React from "react";

const LegalCompliance = () => {
  return (
    <div className="p-8 max-w-4xl mx-auto text-gray-800">
      {/* Header Section */}
      <h1 className="text-3xl font-bold mb-4">Legal & Compliance</h1>
      <p className="mb-6">
        At <span className="font-semibold">Everyone</span>, we believe that a
        truly global platform should be built with care, respect, and
        accountability. As we continue to grow, we’re committed to setting a
        high standard of legal compliance, user protection, and transparency
        across all facets of our operations.
      </p>

      {/* Data Privacy & Security Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Prioritizing Data Privacy & Security
      </h2>
      <p className="mb-4">
        User privacy isn’t just a legal requirement for{" "}
        <span className="font-semibold">Everyone</span>; it’s a fundamental part
        of our commitment to a secure, trusted platform. From GDPR in Europe to
        CCPA in California, we go beyond minimum requirements to ensure that
        personal data remains private, secure, and in our users’ control.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">User Control:</span> Users have
          complete control over their information, with the flexibility to
          manage settings, data sharing, and privacy preferences.
        </li>
        <li>
          <span className="font-semibold">Data Security Standards:</span> We use
          advanced encryption and secure data storage, ensuring that data
          remains private, protected, and accessible only to authorized users.
        </li>
        <li>
          <span className="font-semibold">Global Compliance:</span>{" "}
          <span className="font-semibold">Everyone</span> stays adaptable to the
          shifting landscape of data privacy regulations, maintaining compliance
          with international standards as we expand worldwide.
        </li>
      </ul>

      {/* Advertising & Sponsorship Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Responsible Advertising & Transparent Sponsorships
      </h2>
      <p className="mb-4">
        Trust is everything on a platform like{" "}
        <span className="font-semibold">Everyone</span>, so we’ve built
        transparency directly into our advertising practices. Sponsored content
        is always clearly labeled, and our advertising adheres to standards from
        global regulatory bodies like the FTC in the U.S. and ASA in the U.K.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">Honest Advertising:</span> We ensure
          all brand partnerships reflect accuracy, with no room for misleading
          claims.
        </li>
        <li>
          <span className="font-semibold">Clear Sponsorship Disclosures:</span>{" "}
          Every sponsored post is labeled transparently, helping users make
          informed decisions and keeping our community informed.
        </li>
      </ul>

      {/* Content Moderation Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Content Moderation for a Safe Community
      </h2>
      <p className="mb-4">
        In creating a safe, positive environment, we are deeply committed to
        moderating content for the wellbeing of all users. We actively work to
        prevent harmful or explicit content, ensuring that{" "}
        <span className="font-semibold">Everyone</span> is a platform people can
        trust and feel safe using.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">
            Moderation of Inappropriate Content:
          </span>{" "}
          Graphic, violent, or explicit content is promptly removed to uphold
          the safety and comfort of our users.
        </li>
        <li>
          <span className="font-semibold">AI and Human Oversight:</span> We
          balance AI-driven technology with human review to protect against
          potentially harmful content while fostering a respectful space for
          expression.
        </li>
      </ul>

      {/* Ethical Monetization Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Ethical Monetization for Emerging Technologies
      </h2>
      <p className="mb-6">
        As technology evolves, so do opportunities for responsible growth. We
        are dedicated to ethical practices in monetization, refraining from
        direct monetization in areas where clear regulations are still emerging.
        By taking this responsible stance, we align our growth with industry
        best practices and regulatory developments.
      </p>

      {/* Community Governance Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Community-Driven Governance with Our DAO System
      </h2>
      <p className="mb-4">
        Our Decentralized Autonomous Organization (DAO) system is the
        cornerstone of <span className="font-semibold">Everyone’s</span>{" "}
        community-driven governance. Using blockchain technology, our DAO
        empowers users to shape the future of the platform, ensuring that
        decision-making is transparent, democratic, and inclusive.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">Decentralized Decision-Making:</span>{" "}
          Our DAO operates with a token-based voting system, where users can
          propose and vote on changes to the platform.
        </li>
        <li>
          <span className="font-semibold">Global Inclusivity:</span> Our DAO
          framework enables participation from users around the world, creating
          a diverse community of contributors who bring valuable perspectives to
          platform governance.
        </li>
        <li>
          <span className="font-semibold">
            Transparency and Accountability:
          </span>{" "}
          Blockchain-based voting records and decision-making add a layer of
          transparency, ensuring that every choice is publicly auditable and
          trustworthy.
        </li>
      </ul>

      {/* IP Protection Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Intellectual Property Protection & Compliance
      </h2>
      <p className="mb-4">
        Creativity and originality are essential to{" "}
        <span className="font-semibold">Everyone</span>, and we’re committed to
        respecting intellectual property rights across our platform.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">Proactive Content Review:</span> To
          protect against copyright infringements, we encourage users to share
          only original or properly licensed content.
        </li>
        <li>
          <span className="font-semibold">Transparency in IP Management:</span>{" "}
          We’ve created a clear process for reporting and resolving copyright
          issues, fostering a culture of creativity and respect.
        </li>
      </ul>

      {/* Compliance Monitoring Section */}
      <h2 className="text-2xl font-semibold mb-2">
        Rigorous Compliance Monitoring & Adaptability
      </h2>
      <p className="mb-4">
        Our commitment to compliance is a continuous journey. We conduct regular
        audits and stay informed about evolving regulations, from privacy laws
        to content standards, to ensure{" "}
        <span className="font-semibold">Everyone</span> remains safe, secure,
        and adaptable.
      </p>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>
          <span className="font-semibold">Regular Audits:</span> Our dedicated
          compliance team performs regular audits to evaluate practices,
          policies, and performance against global standards.
        </li>
        <li>
          <span className="font-semibold">Policy Adaptation:</span> As
          regulations and user expectations change, we adjust our policies
          proactively to maintain legal and ethical integrity.
        </li>
      </ul>

      {/* Closing Statement */}
      <p className="text-center font-semibold mt-8">
        By building <span className="font-semibold">Everyone</span> on
        principles of transparency, privacy, and accountability, we’re not only
        creating a platform but fostering a community. This dedication to a
        responsible, user-first approach defines who we are and sets the stage
        for a trusted, resilient future.
      </p>
    </div>
  );
};

export default LegalCompliance;
