import React, { useContext } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { AuthContext } from "components/context/AuthContext";

export default function ChooseUseCasePopup({ setAuthType }) {
  const { setAuth, auth } = useContext(AuthContext);

  function handleChange(value) {
    setAuth((prev) => {
      return {
        ...prev,
        useCase: value,
      };
    });
  }

  function nextStep() {
    if (!auth.useCase) {
      return toast.error("Please select one use case");
    }

    setAuthType((prev) => ({ ...prev, authType: "username" }));
  }

  function prevStep() {
    setAuthType((prev) => ({ ...prev, authType: "dob" }));
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`login-popup p-6 bg-white rounded-xl max-lg:w-11/12 max-w-[850px] overflow-y-auto flex items-center`}
    >
      <div className="flex gap-5 max-lg:flex-col items-center max-lg:justify-center h-full">
        <img
          src="/assets/images/auth/banner.png"
          width={380}
          height={600}
          alt="banner"
          className="aspect-[0.66] max-md:w-full max-md:h-[500px] object-cover max-md:rounded-md max-lg:hidden"
        />

        <div className="ml-5 w-[44%] max-md:ml-0 max-lg:w-full h-full">
          <div className="flex lg:justify-between h-full flex-col text-zinc-900 space-y-3">
            <div className="space-y-3 pb-10">
              <div className="flex items-center justify-center  text-center pb-4  text-2xl font-poppins text-neutral-900 font-medium">
                <div
                  onClick={prevStep}
                  className="p-2 text-slate-700 cursor-pointer"
                >
                  <IoIosArrowBack />
                </div>
                <h1 className="mr-2">Sign Up</h1>
              </div>
              <h1>Choose one</h1>
              <button
                onClick={() => handleChange("Personal use")}
                className="w-full bg-gradient-to-br from-[#00FFAD] via-[#00DDFF] to-[#4093FF] rounded p-[1.5px]"
              >
                <span
                  className={`flex w-full ${
                    auth.useCase === "Personal use"
                      ? "bg-slate-200"
                      : "bg-white"
                  } rounded p-2 pl-6`}
                >
                  Personal use
                </span>
              </button>

              <button
                onClick={() => handleChange("Work/Organization")}
                className="w-full bg-gradient-to-br from-[#00FFAD] via-[#00DDFF] to-[#4093FF] rounded p-[1.5px]"
              >
                <span
                  className={`flex w-full ${
                    auth.useCase === "Work/Organization"
                      ? "bg-slate-200"
                      : "bg-white"
                  }  rounded p-2 pl-6`}
                >
                  Work/Organization
                </span>
              </button>
            </div>

            <div>
              <input
                type="button"
                onClick={nextStep}
                value="Next"
                aria-label="Sign Up"
                className="justify-center items-center px-16 py-3 font-poppins font-semibold text-white bg-rose-500 rounded-lg max-md:px-5 cursor-pointer w-full"
              />

              <div className="justify-center text-center px-1.5 mt-5 leading-6 text-gray-500 font-poppins font-light text-[16px]">
                By continuing, you agree to everyone’s{" "}
                <span className="text-black text-[15px] font-poppins">
                  {" "}
                  Terms of Service
                </span>{" "}
                and confirm that you have read everyone’s
                <span className="text-black font-poppins text-[15px]">
                  {" "}
                  Privacy Policy
                </span>
              </div>
              <div className="self-center mt-3 text-black text-[15px] font-poppins text-center">
                Already have an Account?{" "}
                <button
                  onClick={() =>
                    setAuthType((prev) => ({ ...prev, authType: "login" }))
                  }
                  className="text-rose-500 hover:underline"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
