import React from "react";
import "./dots.scss";

const starImages = [
  "images/stars/Star 3.png",
  "images/stars/Star 4.png",
  "images/stars/Star 5.png",
  "images/stars/Star 6.png",
  "images/stars/Star 7.png",
  "images/stars/Star 8.png",
  "images/stars/Star 9.png",
  "images/stars/Star 10.png",
];

const Stars = ({ dotImage, numDots }) => {
  const generateRandomPosition = () => {
    const x = Math.random() * 100;
    const y = Math.random() * 100;
    return { x, y };
  };

  const generateRandomDelay = () => {
    return Math.random() * 5 + 0 + "s"; // Random delay between 0s and 8s
  };

  const generateRandomDuration = () => {
    return Math.random() * 10 + 4 + "s"; // Random duration between 4s and 10s
  };

  const stars = Array.from({ length: numDots }, (_, index) => {
    const { x, y } = generateRandomPosition();
    const animationDelay = generateRandomDelay();
    const animationDuration = generateRandomDuration();
    const randomStar = Math.floor(Math.random() * 8);

    return (
      <img
        key={index}
        width={150}
        height={150}
        className="dot"
        src={starImages[randomStar]}
        style={{
          left: `${x}%`,
          top: `${y}%`,
          animationDelay,
          animationDuration,
        }}
        alt={`star ${index}`}
      />
    );
  });

  return <div className="fixed w-screen h-screen z-10">{stars}</div>;
};

export default React.memo(Stars);
