"use client";

import { AuthContext } from "components/context/AuthContext";
import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoginPopup from "./LoginPopup";
import RegisterPopup from "./RegisterPopup";
import ChooseUseCasePopup from "./ChooseUseCasePopup";
import DobPopup from "./DobPopup";
import UsernamePopup from "./UsernamePopup";
import PhonePopup from "./PhonePopup";
import PhoneLoginPopup from "./PhoneLoginPopup";
import EmailLoginPopup from "./EmailLoginPopup";
import CustomRegisterPopup from "./CustomRegisterPopup";
import EmailRegisterPopup from "./EmailRegisterPopup";

export default function AuthPopup() {
  const {
    auth,
    authType: state,
    setAuthType: setState,
  } = useContext(AuthContext);

  // useEffect(() => {
  //   console.log(state);
  // });

  // useEffect(() => {
  //   if (window.location.pathname.includes("/auth/twitter")) return;
  //   if (!auth.isLoggedIn && !auth.loading) {
  //     // setTimeout(() => {
  //     setState({ ...state, open: true });
  //     // }, 10000);
  //   } else {
  //     setState({ ...state, open: false });
  //   }
  // }, [auth]);

  function handleClosePopup() {
    setState({ ...state, open: false });
  }

  let authPopup;

  switch (state.authType) {
    case "loading":
      authPopup = <CircularProgress sx={{ color: "white" }} />;
      break;
    case "register":
      authPopup = (
        <RegisterPopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
      break;
    case "custom_register":
      authPopup = <CustomRegisterPopup setAuthType={setState} />;
      break;
    case "email_register":
      authPopup = <EmailRegisterPopup setAuthType={setState} />;
      break;
    case "useCase":
      authPopup = <ChooseUseCasePopup setAuthType={setState} />;
      break;
    case "dob":
      authPopup = <DobPopup setAuthType={setState} />;
      break;
    case "username":
      authPopup = (
        <UsernamePopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
      break;
    case "phone":
      authPopup = (
        <PhonePopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
      break;
    case "email_login":
      authPopup = (
        <EmailLoginPopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
      break;
    case "phone_login":
      authPopup = (
        <PhoneLoginPopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
      break;

    default:
      authPopup = (
        <LoginPopup
          setAuthType={setState}
          close={() => setState((p) => ({ ...p, open: false }))}
        />
      );
  }

  // if (auth?.isLoggedIn) return null;

  return (
    <div className="w-full h-screen flex items-center justify-center py-10 bg-white ">
      {authPopup}
    </div>
  );
}
