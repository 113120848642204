import React from "react";
import {
  Globe2,
  Share2,
  Lightbulb,
  ShoppingCart,
  Bot,
  Shield,
  Video,
  BadgeCheck,
  BarChart2,
  Link,
  Leaf,
} from "lucide-react";

const FeatureCard = ({ title, icon: Icon, description }) => (
  <div className="p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow">
    <div className="flex items-start gap-4">
      <div className="p-2 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div className="flex-1">
        <h3 className="text-lg font-semibold mb-2 text-gray-900">{title}</h3>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

const PlatformFeatures = () => {
  const features = [
    {
      title: "Universal Channel Stream",
      icon: Globe2,
      description:
        "A continuous global stream with each post becoming its own unique channel. Think of it as a never-ending, organic digital timeline, where every contribution has a distinct place and every voice reaches the world without algorithmic interference.",
    },
    {
      title: "Cross-Platform Posting and Messaging",
      icon: Share2,
      description:
        "Everyone seamlessly connects across major social networks, allowing users to post a single piece of content across multiple platforms. With centralized messaging integration, users can communicate with all their contacts from a single inbox, creating a unified experience for messages and posts across networks.",
    },
    {
      title: "Spotlight Ads: Visibility Amplified",
      icon: Lightbulb,
      description:
        "The platform's unique Spotlight feature lets users boost any channel or post for additional visibility, giving creators, businesses, and brands the ability to reach targeted audiences naturally and effectively.",
    },
    {
      title: "In-App Marketplace for Seamless Monetization",
      icon: ShoppingCart,
      description:
        "Everyone's marketplace integrates directly into each channel, enabling creators, freelancers, and businesses to sell products and services efficiently without transaction fees. This setup empowers users to keep 100% of their revenue, a game-changer for earning potential.",
    },
    {
      title: "AI-Powered Assistant: Real-Time Answers",
      icon: Bot,
      description:
        "Everyone's built-in AI offers users an assistant for questions, creative brainstorming, and instant information retrieval. By integrating powerful, on-demand support, the platform creates a space for not just sharing, but for problem-solving and creation.",
    },
    {
      title: "Privacy-Focused Search and Interaction",
      icon: Shield,
      description:
        "Modeled on privacy-first principles, Everyone uses a secure, tracker-free search engine and encrypted messaging, ensuring user activity remains completely private. Every search, post, and interaction is protected by end-to-end encryption.",
    },
    {
      title: "Live Streaming and Real-Time Engagement",
      icon: Video,
      description:
        "Real-time streaming allows users to broadcast live and loop content continuously, creating engaging experiences for events, tutorials, or spontaneous discussions. This feature transforms channels into dynamic, interactive spaces.",
    },
    {
      title: "Verified Channels and Professional Badges",
      icon: BadgeCheck,
      description:
        "Verification badges lend credibility and authority to professionals, brands, and public figures, helping them stand out and foster trust within the global Everyone community.",
    },
    {
      title: "Analytics Dashboard",
      icon: BarChart2,
      description:
        "An integrated analytics suite gives users access to detailed performance metrics, audience insights, and engagement data, supporting professional growth and content strategy optimization.",
    },
    {
      title: "Blockchain-Backed Security for Ownership Transparency",
      icon: Link,
      description:
        "Every post, channel, and transaction is recorded securely using blockchain, ensuring transparency and ownership. This technology enhances trust, making Everyone a secure, decentralized space for users and brands alike.",
    },
    {
      title: "Future-Ready with Sustainable Design",
      icon: Leaf,
      description:
        "Designed for the future, Everyone's infrastructure supports emerging technologies and trends while prioritizing ethical and sustainable practices to reduce environmental impact and ensure long-term value.",
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-12 text-gray-900">
        Platform Features
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            icon={feature.icon}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
};

export default PlatformFeatures;
