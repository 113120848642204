import React, { useEffect, useState } from "react";
import { countryCodes } from "./constants";

const PhoneNumberInput = ({
  phoneNumber,
  setPhoneNumber,
  selectedCode,
  setSelectedCode,
}) => {
  useEffect(() => {
    setPhoneNumber((prev) => {
      return prev
        .replace(/-/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    });
  }, [phoneNumber]);

  return (
    <div className="flex items-center border border-gray-300 rounded-md p-2 w-full overflow-hidden">
      <select
        value={selectedCode}
        onChange={(e) => setSelectedCode(e.target.value)}
        className="block appearance-none bg-white border border-gray-300 rounded-md py-2 p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      >
        {countryCodes.map((country, index) => (
          <option key={index} value={country.code}>
            {country.country} {country.code}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="987-654-321"
        className="ml-2 rounded-md py-2 px-4 focus:outline-none"
      />
    </div>
  );
};

export default PhoneNumberInput;
