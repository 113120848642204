import axios from "axios";
import ToasterService from "utils/toaster.util";

axios.interceptors.response.use(
  (res) => {
    const { message } = res.data;

    ToasterService.showSuccess(message);

    return res;
  },
  (err) => {
    const { message: msg, response } = err;
    const message = response?.data?.message;

    ToasterService.showError(message || msg);

    throw err;
  }
);
const http = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  patch: axios.patch,
  delete: axios.delete,
};

export default http;
