import React from "react";

const EveryonePlatform = () => {
  return (
    <div className="p-8 max-w-4xl mx-auto space-y-8">
      {/* Section 1 */}
      <section>
        <h2 className="text-2xl font-semibold">
          A Global Sequentially Numbered Channel Stream for Everything
        </h2>
        <p className="mt-2 text-gray-600">
          At Everyone, each post is assigned the next available unique channel
          number, capturing it forever in an unbreakable timeline. Early
          adopters secure prime positions in this evolving stream, claiming
          valuable digital real estate that grows in significance as the
          platform expands.
        </p>
      </section>
      <img src="assets/g1.gif" />
      {/* Section 2 */}
      <section>
        <h2 className="text-2xl font-semibold">
          Unmatched Visibility & Engagement
        </h2>
        <p className="mt-2 text-gray-600">
          Our Global Stream is the heart of Everyone, a space where each post
          finds its unique moment and every voice is amplified. Unlike
          traditional platforms, our chronological stream ensures that every
          post reaches a global audience in real time, fostering visibility and
          engagement that builds lasting value.
        </p>
      </section>

      {/* Section 3 */}
      <section>
        <h2 className="text-2xl font-semibold">
          Universal Posting Made Simple
        </h2>
        <p className="mt-2 text-gray-600">
          Everyone seamlessly integrates with major social platforms and
          decentralized networks, empowering users to share across multiple
          networks with a single post. Ideal for influencers, businesses, and
          creators seeking maximum reach with minimal effort, Everyone is an
          invaluable tool for consistent, effortless connection.
        </p>
      </section>

      {/* Section 4 */}
      <section>
        <h2 className="text-2xl font-semibold">
          A Platform with Zero Fees, Premium Tools
        </h2>
        <p className="mt-2 text-gray-600">
          We're redefining the norm with a zero-fee model that supports
          creators, freelancers, and sellers. Every dollar earned belongs to the
          user. With premium features like FAST Channels, integrated meeting
          spaces, and a marketplace, Everyone is built for those who want to
          create, sell, and connect without hidden costs.
        </p>
      </section>
      <img src="assets/g2.gif" />
      {/* Section 5 */}
      <section>
        <h2 className="text-2xl font-semibold">
          Enhanced Communication with Secure Messaging
        </h2>
        <p className="mt-2 text-gray-600">
          Public conversation meets private security. Everyone offers encrypted
          messaging for secure, private conversations within the same ecosystem,
          ensuring users have the freedom to engage openly or confidentially in
          one place.
        </p>
      </section>

      {/* Section 6 */}
      <section>
        <h2 className="text-2xl font-semibold">Blockchain-Powered Security</h2>
        <p className="mt-2 text-gray-600">
          The Everyone Blockchain ensures that every post, interaction, and
          channel is securely recorded, providing transparency and privacy for
          users. Our blockchain technology protects user data while celebrating
          individual contributions to a shared, global timeline.
        </p>
      </section>

      {/* Section 7 */}
      <section>
        <h2 className="text-2xl font-semibold">Why Invest in Everyone?</h2>
        <ul className="mt-2 space-y-2 text-gray-600">
          <li>
            <strong>Capturing Market Demand:</strong> Everyone addresses the
            growing need for integrated, user-centric platforms where voices can
            reach a global audience without limits.
          </li>
          <li>
            <strong>Unique Value Proposition:</strong> Combining global
            visibility, cross-platform posting, and secure messaging, an
            offering unmatched in today’s digital landscape.
          </li>
          <li>
            <strong>Scalable Business Model:</strong> Our zero-fee approach
            attracts a broad user base, driving rapid adoption and retention as
            people realize the full value of Everyone's premium tools.
          </li>
        </ul>
      </section>

      {/* Section 8 */}
      <section>
        <h2 className="text-2xl font-semibold">
          Join Us in Shaping the Future
        </h2>
        <p className="mt-2 text-gray-600">
          Everyone is not just a platform, it's a global gathering place. With
          innovative features, a user-first approach, and a commitment to
          empowering voices everywhere, Everyone is poised to become the digital
          meeting place for generations to come.
        </p>
      </section>
      <img src="assets/g3.gif" />
    </div>
  );
};

export default EveryonePlatform;
